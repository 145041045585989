/**
 * Keycloak component, wraps app for authentication and protected routes
 */
import React, { ReactNode, Suspense, lazy, useEffect, useState } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import jwt from "jwt-decode";
import { useUser } from "../../context/user-context";
import { AuthClientEvent, AuthClientTokens } from "@react-keycloak/core";
import { getKeycloakConfig } from "../../keycloak";
import Keycloak, { KeycloakTokenParsed } from "keycloak-js";
import { addToast } from "state/slices/toast";
import { setKeycloakState } from "state/slices/keycloak";
import { useAppDispatch } from "state";
import userSettingsApi from "state/api/mvcrApi/user-settings";
import { useGetAccountInfoQuery } from "state/api/mvcrApi/account-service";
import { values } from "lodash";

const KeycloakContext: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const state = useUser();
  const dispatch = state.dispatch;
  const reduxDispatch = useAppDispatch();
  const [keycloakConfig, setKeycloakConfig] = useState<Keycloak>();
  const [accessDenied, setAccessDenied] = useState(false);

  const keycloakProviderInitConfig = {
    onLoad: "login-required",
    enableLogging: false,
    checkLoginIframe: false
  };

  const { error, refetch } = useGetAccountInfoQuery();

  const UnauthorizedPage = lazy(
    () =>
      import(/* webpackChunkName: "UnauthorizedPage" */ "../../pages/unauthorized")
  );

  const onTokens = (tokens: AuthClientTokens) => {
    // Decoded the token value and dispatch it
    const token = tokens?.token ? tokens.token : null;
    const user: KeycloakTokenParsed | undefined = token
      ? jwt(token)
      : undefined;
    if (!window.init_login) {
      window.init_login = true;
      refetch();
    }

    const onUserTokenAndProfile = (data: unknown) => {
      dispatch({
        type: "SET_USER_TOKEN",
        user,
        profile: data,
        token
      });
      // Duplicate the keycloak data to Redux so it's accessible outside of
      // React
      reduxDispatch(
        setKeycloakState({
          user,
          token,
          profile: data,
        })
      );

      userSettingsApi.endpoints.getUserSettingsById.initiate({
        userId: keycloakConfig?.subject
      });
    };

    if (user && token && keycloakConfig?.profile) {
      onUserTokenAndProfile(keycloakConfig.profile);
    } else if (user && token) {
      keycloakConfig
        .loadUserProfile()
        .then((data) => {
          onUserTokenAndProfile(data);
        })
        .catch((err) => {
          setAccessDenied(true);
          console.error(new Error(err));
        });
    } else console.error("Unable to retrieve user information");
  };

  const onEvent = (eventType: AuthClientEvent) => {
    if (eventType === "onAuthError" || eventType === "onAuthLogout")
      dispatch({ type: "CLEAR_TOKEN" });
  };

  useEffect(() => {
    // Add toast message error for now as account service login is not application breaking
    if (error && values(error).some((x) => x !== undefined)) {
      let errorMsg = "";
      if ("status" in error) {
        errorMsg = error.status;
      } else if ("message" in error) {
        errorMsg = error.message;
      }
      dispatch(
        addToast({
          toastData: {
            labels: ["STANDARD", "FIRE AND FORGET"],
            toastConfig: {
              msg: `A login error has occurred. The application will not function as expected. Please login and try again. Error message: ${errorMsg}`,
              title: "Account Service Error",
              color: "DANGER"
            }
          }
        })
      );
    }
  }, [error, dispatch]);

  const initializeKeycloak = async () => {
    const keycloak = await getKeycloakConfig();
    if (keycloak) setKeycloakConfig(keycloak);
  };

  useEffect(() => {
    initializeKeycloak();
  }, []);

  if (!keycloakConfig) return null;

  return (
    <ReactKeycloakProvider
      authClient={keycloakConfig}
      initOptions={keycloakProviderInitConfig}
      onTokens={onTokens}
      onEvent={onEvent}
    >
      {accessDenied ? (
        <Suspense fallback={<div />}>
          <UnauthorizedPage />
        </Suspense>
      ) : (
        children
      )}
    </ReactKeycloakProvider>
  );
};

export default KeycloakContext;

import handleHttpError from "./handleHttpError";
import handleHttpResponse from "./handleHttpResponse";
import HttpsService from "./httpsService";

const url = `${process.env.PROPAGATION_SVC_BASE_URL}${process.env.API_URL}/propagation-svc`;

const ImageService = {
  get: <T>(id: string): Promise<T | unknown> => {
    const https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${url}/image/${id}`
      })
      .then((res: any) =>
        handleHttpResponse(res, "Failed to retrieve the image")
      )
      .catch((err: any) => handleHttpError(err));
  },
  getAsArrayBuffer: <T>(id: string): Promise<T | unknown> => {
    const https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${url}/image/${id}`,
        responseType: "arraybuffer"
      })
      .then((res: any) =>
        handleHttpResponse(res, "Failed to retrieve the image")
      )
      .catch((err: any) => handleHttpError(err));
  },
  getUploadedFile: <T>(id: string): Promise<T | unknown> => {
    const https = HttpsService.getHttps();
    return https
      ?.get(`${url}/upload/${id}`, {
        responseType: "blob",
        timeout: 30000
      })
      .then((res: any) =>
        handleHttpResponse(res, "Failed to retrieve the uploaded file")
      )
      .catch((err: any) => handleHttpError(err));
  },
  uploadFile: <T>(file: any, extension?: string): Promise<T | unknown> => {
    const formData = new FormData();
    formData.append("uploadedFile", file);
    if (extension) formData.append("extension", extension);
    const https = HttpsService.getHttps();
    return https
      ?.post(`${url}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data; charset=UTF-8"
        }
      })
      .then((res: any) => handleHttpResponse(res, "Failed to upload file"))
      .catch((err: any) => handleHttpError(err));
  },
  deleteUploadedFile: <T>(id: string): Promise<T | unknown> => {
    const https = HttpsService.getHttps();
    return https
      ?.request({
        method: "delete",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${url}/upload/${id}`
      })
      .then((res: any) => handleHttpResponse(res, "Failed to delete the image"))
      .catch((err: any) => handleHttpError(err));
  }
};

export default ImageService;

import { store } from "state";

/**
 * Adjust app elements when exercise banner is displayed/hidden
 */
export const adjustCesiumElements = (showExerciseBanner?: boolean) => {
  let isExerciseBannerShowing = showExerciseBanner;
  if (isExerciseBannerShowing === undefined) {
    isExerciseBannerShowing = store.getState()["ui-state"].showExerciseBanner;
  }

  // List of components to adjust, must be separated by a comma and space with className indicator (.)
  const cesiumElements = document.querySelectorAll(
    ".cesium-viewer-toolbar, .compass, .navigation-controls"
  );

  if (isExerciseBannerShowing)
    cesiumElements?.forEach((element) =>
      element.classList.add("exercise-banner-adjust")
    );
  else
    cesiumElements?.forEach((element) =>
      element.classList.remove("exercise-banner-adjust")
    );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { createUseSliceSelector } from "state/util";
import { TimelineSettingsModel } from "@mvcr/mvcr-common";
import { speedOptions, zoomOptions } from "components/timeline/constants";

export interface TimelineSettingsState {
  /**
   * Stored Timeline state.
   */
  timelineSettings: TimelineSettingsModel | null;
}

export interface TimelineSettingsPayload {
  timelineSettings: TimelineSettingsModel;
}

export interface PartialTimelineSettingsPayload {
  timelineSettings: Partial<TimelineSettingsModel>;
}

const initialState: TimelineSettingsState = {
  timelineSettings: {
    zoomLevel: zoomOptions.find((option) => option.default).value,
    playbackSpeed: speedOptions.find((option) => option.default).value,
    dateStart: undefined,
    dateEnd: undefined,
    playbackStart: undefined,
    playbackEnd: undefined,
    isVisible: true,
    showHistogram: false,
    showAllData: false,
    stepSize: 1
  }
};

export const timelineSettingsSlice = createSlice({
  name: "timelineSettings",
  initialState,
  reducers: {
    /**
     * Overwrite the Timeline Settings value or create one if none exists
     */
    setTimelineSettings(state, action: PayloadAction<TimelineSettingsPayload>) {
      set(state, "timelineSettings", action.payload.timelineSettings);
    },
    /**
     * Updated Timeline Settings values. A value will only be changed
     * if it is defined in the payload.
     */
    updateTimelineSettings(
      state,
      action: PayloadAction<PartialTimelineSettingsPayload>
    ) {
      const tempSettings = {
        ...(state.timelineSettings ?? {}),
        ...action.payload.timelineSettings
      };
      set(state, "timelineSettings", tempSettings);
    }
  }
});

export const { setTimelineSettings, updateTimelineSettings } =
  timelineSettingsSlice.actions;

export const useTimelineSettingsSelector = createUseSliceSelector(
  timelineSettingsSlice
);

export default timelineSettingsSlice;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AnalysisJobStatusValue,
  PARequest,
  PropagationAnalysis
} from "components/propagation/model";
import { set } from "lodash";
import { createUseSliceSelector } from "state/util";

// Maps PA _id -> PropagationAnalysis
export type PropagationStates = {
  workspacePropagations: Record<string, PropagationAnalysis<PARequest>>;
  userPropagations: Record<string, PropagationAnalysis<PARequest>>;
};

export interface KeyInterface {
  propagationId: string;
}

export interface PropagationPayload {
  propagation: PropagationAnalysis<PARequest>;
}

export interface PartialPropagationPayload {
  propagation: Partial<PropagationAnalysis<PARequest>>;
}

const initialState: PropagationStates = {
  workspacePropagations: {},
  userPropagations: {}
};

/**
 * Determine if propagation update can proceed based on the stored
 * and incoming job status and progress
 * @param storedPropagation Stored data for PA request
 * @param incomingPropagation Incoming data for PA request
 */
const proceedWithProgressUpdate = (
  storedPropagation: PropagationAnalysis<PARequest>,
  incomingPropagation: PropagationAnalysis<PARequest>
): boolean => {
  if (
    storedPropagation.queueStatus === "IN PROGRESS" &&
    incomingPropagation.queueStatus === "IN PROGRESS"
  ) {
    // If both are IN PROGRESS, prevent backwards progress updates
    if (storedPropagation.jobProgress <= incomingPropagation.jobProgress)
      return true;
    else return false;
  }
  // Any other non-COMPLETE change to stored status
  return true;
};

/**
 * When an updated propagation is provided, check to verfiy that the queue status
 * is not moving from a valid state to an invalid one.  Potential cause of this
 * would be the UI receiving the status updates for a PA job out of order, i.e.
 * an IN PROGRESS status comes in after the COMPLETE status.
 * @param propagationId Updated propagation
 * @param incomingPropagation Updated propagation object
 * @param propagationList List of propagation
 */
const verfiyStatusUpdateTransition = (
  propagationId: string,
  incomingPropagation: PropagationAnalysis<PARequest>,
  propagationList: Record<string, PropagationAnalysis<PARequest>>
) => {
  const storedStatus: AnalysisJobStatusValue =
    propagationList[propagationId].queueStatus;
  const incomingStatus: AnalysisJobStatusValue =
    incomingPropagation.queueStatus;

  /**
   * Process updated propagation if:
   * - is not moving into an invalid state (complete -> in progress)
   * - job is incomplete and not moving backwards in progress
   */
  if (
    (storedStatus === "COMPLETE" && incomingStatus !== "IN PROGRESS") ||
    proceedWithProgressUpdate(
      propagationList[propagationId],
      incomingPropagation
    )
  ) {
    propagationList[propagationId] = {
      ...propagationList[propagationId],
      ...incomingPropagation
    };
  }
};

export const propagationSlice = createSlice({
  name: "propagations",
  initialState,
  reducers: {
    /**
     * Overwrite the current list of workspace propagations,
     * or create one in state if it doesn't exist.
     */
    setWorkspacePropagations(
      state,
      action: PayloadAction<PropagationAnalysis<PARequest>[]>
    ) {
      const props = action.payload;
      props.forEach((prop) => set(state.workspacePropagations, prop._id, prop));
    },
    /**
     * Overwrite the current list of user propagations,
     * or create one in state if it doesn't exist.
     */
    setUserPropagations(
      state,
      action: PayloadAction<PropagationAnalysis<PARequest>[]>
    ) {
      const props = action.payload;
      props.forEach((prop) => set(state.userPropagations, prop._id, prop));
    },
    /**
     * Updates a propagation in either user and/or workspace propagation lists
     */
    updatePropagation(
      state,
      action: PayloadAction<KeyInterface & PropagationPayload>
    ) {
      const { propagationId, propagation } = action.payload;

      /**
       * Due to socket.io ingestion of PA job status updates, it is possilbe to receive
       * an update action before a newly created PA request can be added to the slice.
       *
       * Check to see if the PA needs to be added, otherwise update in appropriate list(s)
       */

      // If propagation does not exist in either list, add to both
      if (
        state?.workspacePropagations?.[propagationId] === undefined &&
        state?.userPropagations?.[propagationId] === undefined
      ) {
        state.workspacePropagations[propagationId] = propagation;
        state.userPropagations[propagationId] = propagation;
      } else {
        // Propagation can be in one or both lists

        // Check for propagation in workspace list
        if (state?.workspacePropagations?.[propagationId]) {
          verfiyStatusUpdateTransition(
            propagationId,
            propagation,
            state.workspacePropagations
          );
        }

        // Check for propagation in user list
        if (state?.userPropagations?.[propagationId]) {
          verfiyStatusUpdateTransition(
            propagationId,
            propagation,
            state.userPropagations
          );
        }
      }
    },
    /**
     * Adds a propagation to both propagation lists as it has been created
     * by current user on current workspace
     */
    addPropagation(
      state,
      action: PayloadAction<KeyInterface & PropagationPayload>
    ) {
      const { propagationId, propagation } = action.payload;

      /**
       * Due to socket.io ingestion of PA job status updates, it is possilbe to receive
       * an update action before a newly created PA request can be added to the slice.
       *
       * Check to see if the PA is already in the jobs list:
       * Update, if present
       * Otherwise, add new PA job
       */

      // Check for propagation in workspace list
      if (state?.workspacePropagations?.[propagationId]) {
        state.workspacePropagations[propagationId] = {
          ...state.workspacePropagations[propagationId],
          ...propagation
        };
      } else {
        state.workspacePropagations[propagationId] = propagation;
      }

      // Check for propagation in user list
      if (state?.userPropagations?.[propagationId]) {
        state.userPropagations[propagationId] = {
          ...state.userPropagations[propagationId],
          ...propagation
        };
      } else {
        state.userPropagations[propagationId] = propagation;
      }
    },
    /**
     * Remove a propagation from the stored list(s)
     */
    deletePropagation(state, action: PayloadAction<KeyInterface>) {
      const { propagationId } = action.payload;

      // Do nothing if propagation does not exist in either list
      if (
        state?.workspacePropagations?.[propagationId] === undefined &&
        state?.userPropagations?.[propagationId] === undefined
      ) {
        console.warn(
          `Propagation ${propagationId} was never initialized in the store for user nor workspace (delete)`
        );
        return;
      }

      delete state.workspacePropagations[propagationId];
      delete state.userPropagations[propagationId];
    }
  }
});

export const {
  setWorkspacePropagations,
  setUserPropagations,
  updatePropagation,
  addPropagation,
  deletePropagation
} = propagationSlice.actions;

export const usePropagationSelector = createUseSliceSelector(propagationSlice);

export default propagationSlice;

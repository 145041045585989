import handleHttpError from "./handleHttpError";
import handleHttpResponse from "./handleHttpResponse";
import HttpsService from "./httpsService";

const WorkspaceService = {
  verifyUniqueWorkspaceName: (name: string, excludeWorkSpaceId?: string) => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.WORKSPACE_SVC_BASE_URL}${process.env.API_URL}/workspace-svc/verifyUniqueName`,
        data: { name, excludeWorkSpaceId }
      })
      .then((res) => handleHttpResponse(res, "Failed to get all workspaces"))
      .catch((err) => handleHttpError(err, []));
  }
};

export default WorkspaceService;

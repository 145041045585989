import moment from "moment";
import { SpeedOption, TimelineZoomLevel } from "@mvcr/mvcr-common";

/**
 * Configuration options for the timeline speeds to select from
 */
export const speedOptions: SpeedOption[] = [
  {
    default: true,
    text: "1 sec",
    value: 1
  },
  {
    text: "15 sec",
    value: 15
  },
  {
    text: "30 sec",
    value: 30
  },
  {
    text: "1 min",
    value: 60
  },
  {
    text: "15 min",
    value: 900
  },
  {
    text: "30 min",
    value: 1800
  },
  {
    text: "1 hr",
    value: 3600
  },
  {
    text: "6 hr",
    value: 21600
  },
  {
    text: "12 hr",
    value: 43200
  },
  {
    text: "1 day",
    value: 86400
  },
  {
    text: "1 wk",
    value: 604800
  }
];

/**
 * Options for the Zoom Level select.
 * Values are in seconds
 */
export const zoomOptions: TimelineZoomLevel[] = [
  {
    text: "15 sec",
    value: moment.duration(15, "s").asSeconds(),
    range: "seconds",
    format: "HH:mm:ss"
  },
  {
    text: "30 sec",
    value: moment.duration(30, "s").asSeconds(),
    range: "seconds",
    format: "HH:mm:ss",
    default: true
  },
  {
    text: "1 min",
    value: moment.duration(1, "m").asSeconds(),
    range: "seconds",
    format: "HH:mm:ss"
  },
  {
    text: "15 min",
    value: moment.duration(15, "m").asSeconds(),
    range: "minutes",
    format: "HH:mm"
  },
  {
    text: "30 min",
    value: moment.duration(30, "m").asSeconds(),
    range: "minutes",
    format: "HH:mm"
  },
  {
    text: "1 hr",
    value: moment.duration(1, "h").asSeconds(),
    range: "minutes",
    format: "HH:mm"
  },
  {
    text: "3 hr",
    value: moment.duration(3, "h").asSeconds(),
    range: "hours",
    format: "HH:mm"
  },
  {
    text: "12 hr",
    value: moment.duration(12, "h").asSeconds(),
    range: "hours",
    format: "HH:mm"
  },
  {
    text: "1 day",
    value: moment.duration(1, "d").asSeconds(),
    range: "hours",
    format: "HH:mm"
  },
  {
    text: "3 day",
    value: moment.duration(4, "d").asSeconds(),
    range: "days",
    format: "MMM DD"
  },
  {
    text: "1 week",
    value: moment.duration(1, "w").asSeconds(),
    range: "days",
    format: "MMM DD"
  },
  {
    text: "2 week",
    value: moment.duration(2, "w").asSeconds(),
    range: "days",
    format: "MMM DD"
  },
  {
    text: "1 month",
    value: moment.duration(1, "M").asSeconds(),
    range: "days",
    format: "MMM DD"
  },
  {
    text: "3 month",
    value: moment.duration(3, "M").asSeconds(),
    range: "weeks",
    format: "MMM DD"
  },
  {
    text: "6 month",
    value: moment.duration(6, "M").asSeconds(),
    range: "months",
    format: "MMM DD"
  },
  {
    text: "1 year",
    value: moment.duration(1, "y").asSeconds(),
    range: "months",
    format: "MMM"
  }
];

/**
 * We don't want the step size box to ever be so small the user can't find it
 */
export const minimumStepSizeBoxWidth = 5;

/**
 * The amount of time between overdrag intervals
 */
export const overdragDelay: number = 100;

/**
 * The margin we use from the left/right of the window to the
 * start/end of the timeline ticks
 */
export const timelineMargin: number = 25;

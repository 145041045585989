import { BaseballCardDefinitionResponse } from "../@types/baseball-card.model";
import handleHttpResponse from "./handleHttpResponse";
import HttpsService from "./httpsService";
import { AreaOfInterestModel } from "../@types/AreaOfInterest.model";
import { DataSourceTitle } from "../@types/filter.model";
import {
  CapabilityComparisonConfigurationsDataSet,
  CapabilityComparisonConfigurationsRequestParams,
  CapabilityComparisonDataSet,
  CapabilityComparisonRequestParams
} from "../@types/capability-comparison.model";
import {
  BBCDataSetQueryDefinition,
  SourceDefinition,
  WorkspaceFilter
} from "@mvcr/mvcr-common";

/**
 * @param {WorkspaceFilter[]} filters Selected form filters
 * @param {Record<DataSourceTitle, SourceDefinition>} sources Datasource to search
 * @param {AreaOfInterestModel} aoi Areas to search
 */
export type QueryRequestParams = {
  filters: WorkspaceFilter[];
  sources: Record<DataSourceTitle, SourceDefinition>;
  aoi: AreaOfInterestModel;
};

// Baseball card data
const SpectrumInfoService = {
  getBaseballCardForRecord: async (
    data: BBCDataSetQueryDefinition
  ): Promise<BaseballCardDefinitionResponse> => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.SPECTRUM_INFO_SVC_BASE_URL}${process.env.API_URL}/spectrum-info-svc/baseball-cards`,
        data
      })
      .then((res) =>
        handleHttpResponse(res, "Failed to retrieve baseball card information.")
      );
  },
  getCapabilityComparisonEquipment: async (
    params: CapabilityComparisonRequestParams
  ): Promise<CapabilityComparisonDataSet> => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.SPECTRUM_INFO_SVC_BASE_URL}${process.env.API_URL}/spectrum-info-svc/capability-comparison`,
        data: params
      })
      .then((res) =>
        handleHttpResponse(
          res,
          "Failed to retrieve capability comparison equipment."
        )
      );
  },
  getCapabilityComparisonEquipmentConfigurations: async (
    params: CapabilityComparisonConfigurationsRequestParams
  ): Promise<CapabilityComparisonConfigurationsDataSet> => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.SPECTRUM_INFO_SVC_BASE_URL}${process.env.API_URL}/spectrum-info-svc/capability-comparison/configurations`,
        data: params
      })
      .then((res) =>
        handleHttpResponse(
          res,
          "Failed to retrieve capability comparison configurations."
        )
      );
  },
  /**
   * Retrieves all available unique system_nomenclatures in the equipment table.
   * @returns
   */
  getAllEquipmentSystemNomenclatures: async (): Promise<any> => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.SPECTRUM_INFO_SVC_BASE_URL}${process.env.API_URL}/spectrum-info-svc/equipment-data`
      })
      .then((res) =>
        handleHttpResponse(res, "Failed to retrieve all equipment data.")
      )
      .then((res) => res); // data is in res.body?.aggregations?.system_nomenclature_agg?.buckets);
  },
  /**
   * Retrieves the specific equipment record for the id given.
   * @returns
   */
  getEquipmentDataById: async (equipmentId: string): Promise<any> => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.SPECTRUM_INFO_SVC_BASE_URL}${
          process.env.API_URL
        }/spectrum-info-svc/equipment-data/${encodeURIComponent(equipmentId)}`
      })
      .then((res) =>
        handleHttpResponse(res, "Failed to retrieve equipment data.")
      )
      .then((res) => res); // data is in res.body?.hits?.hits[0]?._source
  },
  /**
   * Retrieves the available configurations for a specific system_nomenclature for equipment.
   * @returns
   */
  getConfigurationDataBySystemNomenclature: async (
    systemNomenclature: string
  ): Promise<any> => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.SPECTRUM_INFO_SVC_BASE_URL}${
          process.env.API_URL
        }/spectrum-info-svc/equipment-data-configuration/${encodeURIComponent(
          systemNomenclature
        )}`
      })
      .then((res) =>
        handleHttpResponse(res, "Failed to retrieve equipment data.")
      )
      .then((res) => res); // data is in res.body?.hits?.hits);
  }
};

export default SpectrumInfoService;

import { PopoverAnchorPosition } from "@elastic/eui";

/**
 * the properties for an individual tour step
 * @param {number} step the current step in the tour
 * @param {string} title tour title
 * @param {string} body copy for the tour card
 * @param {string} [link] the url for the optional documentation link
 * @param {string} [linkIcon] the elastic ui icon type
 * @param {string} [linkLabel] the url label
 * @param {string} anchor the id for the ui element this tour step is anchored to
 * @param {PopoverAnchorPosition} anchorPosition the position of the tour step in relation to the anchor element
 */
export interface TourStepModel {
  step: number;
  title: string;
  body: string;
  link?: string;
  linkIcon?: string;
  linkLabel?: string;
  anchor: string;
  anchorPosition: PopoverAnchorPosition;
}

/**
 * Properties used to provided configuration options for tour
 * @param {boolean} skipMissingSteps whether tour should be displayed if steps are missing
 */
export interface TourConfigurationModel {
  skipMissingSteps: boolean;
}

/**
 * Base model of each configured tour available
 * @param {TourConfigurationModel} configuration base tour setup
 * @param {TourStepModel[]} steps list of steps in tour
 */
export interface TourModel {
  configuration: TourConfigurationModel;
  steps: TourStepModel[];
}

/**
 * list of configured tours stored in database
 * @param {TourStepModel[]} onboarding
 */
export interface AvailableToursModel {
  onboarding: TourModel;
}

/**
 * Types of tours available
 */
export type TourTypes = "onboarding";

/**
 * List of tours currently available in an iterable format
 */
export enum AvailableTourTypes {
  ONBOARDING = "onboarding"
}

/**
 * List of registered tour steps (component className or id) per tour type
 */
export type RegisteredTourSteps = Record<TourTypes, Set<string>>;

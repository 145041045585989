export enum DrawingMode {
  NONE = "NONE",
  AOI = "AOI",
  PPA = "PPA",
  PROPLOSS = "PROPLOSS",
  LOS = "LOS",
  RADAR = "RADAR",
  BBC = "BASEBALL_CARD",
  RADAR_COVERAGE = "RADAR_COVERAGE"
}

import { DatasourceStatus } from "@mvcr/mvcr-common/dist/models/datasourceStatus.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUseSliceSelector, createUseValueSelector } from "state/util";

export type DatasourceStatusState = {
  datasourceStatus: DatasourceStatus[];
};

const initialState: DatasourceStatusState = {
  datasourceStatus: []
};

export const datasourceStatusSlice = createSlice({
  name: "datasourceStatus",
  initialState,
  reducers: {
    setDatasourceStatus(state, action: PayloadAction<DatasourceStatus>) {
      const index = state.datasourceStatus.findIndex(
        (ds) => ds.name === action.payload.name
      );
      if (index !== -1) {
        if (state.datasourceStatus[index].health !== action.payload.health) {
          if (action.payload.health === 1) {
            console.log(
              `Datasource '${action.payload.name}' has recovered; flagging as HEALTHY.`
            );
          } else {
            console.warn(
              `Datasource '${action.payload.name}' is down; flagging as UNHEALTHY.`
            );
          }
          state.datasourceStatus[index] = action.payload;
        }
      } else {
        state.datasourceStatus.push(action.payload);
      }
    }
  }
});

export const { setDatasourceStatus } = datasourceStatusSlice.actions;

export const useDatasourceStatusSliceSelector = createUseSliceSelector(
  datasourceStatusSlice
);

export const useDatasourceStatusSelector = createUseValueSelector(
  datasourceStatusSlice,
  "datasourceStatus"
);

export default datasourceStatusSlice;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationModel } from "@mvcr/mvcr-common";
import { cloneDeep, findIndex } from "lodash";
import { createUseSliceSelector } from "state/util";
import { v4 as uuid } from "uuid";

export type LocalNotificationModel = Partial<NotificationModel>;

/**
 * The shape of this slice's state
 */
export interface ToastState {
  toastList: NotificationModel[] | LocalNotificationModel[];
}

/**
 * The object shape expected when anything uses one of the exposed reducers
 */
export interface ToastPayload {
  toastData: NotificationModel | LocalNotificationModel;
}

/**
 * The interface used to locate individual toasts within the toastList
 */
export interface KeyInterface {
  toastId: string;
}

/**
 * The form of the initial state, prior to any additions
 */
const initialState: ToastState = {
  toastList: []
};

/**
 * A single source of truth for toasts
 * The major benefit of this is the ability for any aspect of the application to add toasts to the list
 */
export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    /**
     * Add a toast to the toast list
     */
    addToast(state, action: PayloadAction<ToastPayload>) {
      if (action?.payload?.toastData) {
        state.toastList = [
          ...state.toastList,
          {
            ...action.payload.toastData,
            _id: action.payload.toastData?._id || uuid()
          }
        ];
      }
    },
    /**
     * Adds multiple toasts to the toast list
     */
    addToasts(state, action: PayloadAction<ToastPayload[]>) {
      if (action?.payload?.length) {
        const newToasts = action.payload.map((toast) => ({
          ...toast.toastData,
          _id: toast.toastData?._id || uuid()
        }));
        state.toastList = [
          ...state.toastList,
          ...newToasts
        ];
      }
    },
    /**
     * Remove a toast from the toast list
     */
    removeToast(state, action: PayloadAction<KeyInterface>) {
      const clonedToastList: NotificationModel[] | LocalNotificationModel[] = cloneDeep(state.toastList);
      const findToastFromList = findIndex(
        clonedToastList,
        (toast) => toast._id === action?.payload?.toastId
      );

      if (findToastFromList > -1) {
        clonedToastList.splice(findToastFromList, 1);
        state.toastList = clonedToastList;
      }
    }
  }
});

export const { addToast, addToasts, removeToast } = toastSlice.actions;

export const useToastSelector = createUseSliceSelector(toastSlice);

export default toastSlice;

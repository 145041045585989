import React from "react";
import { CesiumViewerProvider } from "./context/cesium-viewer-context";
import { store } from "./state"; // this import needs to be on the top!
import { CookiesProvider } from "react-cookie";
import { UserProvider } from "./context/user-context";
import KeycloakContext from "./components/keycloak-context";
import { Provider as ReduxProvider } from "react-redux";
import ErrorBoundary from "components/error-boundary";
import { LoadingOverlayProvider } from "context/loading-overlay-context";
import { TourStepTracker } from "components/tour/tour-step-tracker";

export const Provider: React.FunctionComponent = ({ children }) => {
  return (
    <CesiumViewerProvider>
      <ReduxProvider store={store}>
        <ErrorBoundary>
          <UserProvider>
            <KeycloakContext>
              <CookiesProvider>
                <TourStepTracker>
                  <LoadingOverlayProvider>{children}</LoadingOverlayProvider>
                </TourStepTracker>
              </CookiesProvider>
            </KeycloakContext>
          </UserProvider>
        </ErrorBoundary>
      </ReduxProvider>
    </CesiumViewerProvider>
  );
};

import { useAppSelector } from ".";
import { TypedUseSelectorHook } from "react-redux";
import { Slice } from "@reduxjs/toolkit";

export interface UseSliceSelector<T> {
  (dashboardSlice: Slice<T>): TypedUseSelectorHook<T>;
}

/**
 * Utility function for creating a selector that returns the slice
 * @param {Slice<T>} slice
 * @returns the specified slice
 */
export function createUseSliceSelector<T>(
  slice: Slice<T>
): TypedUseSelectorHook<T> {
  return (selector) => useAppSelector((state) => selector(state[slice.name]));
}

/**
 * Utility function for creating a selector returning only the specified key
 * from the slice (with an optional function to determine equality)
 * @param {Slice<T>} slice - the slice
 * @param {K} key - the key in the slice
 * @param {(left: any, right: any) => boolean} equalityFn - optional equality function to be used by the selector when
 *    determining if the value has changed. One example would be to use lodash's `isEqual` function to compare the values
 *    of an array instead of the array itself
 * @returns - the specified key
 */
export function createUseValueSelector<T, K extends keyof T>(
  slice: Slice<T>,
  key: K,
  equalityFn?: (left: any, right: any) => boolean
): () => T[K] {
  if (equalityFn)
    return () => useAppSelector((state) => state[slice.name][key], equalityFn);
  else return () => useAppSelector((state) => state[slice.name][key]);
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { createUseSliceSelector } from "state/util";

/**
 * The time to force the currentTime state in the application
 * @param currentTimeChange - The valueOf time to use to set the currentTime in the application
 */
export interface CurrentTimeChangeState {
  currentTimeChange: number;
}

const initialState: CurrentTimeChangeState = {
  currentTimeChange: undefined
};

/**
 * !!!IMPORTANT!!!
 * This should be used very carefully and in very specifc scenarios where we want an outside component (away from the timeline components) to set the currentTime state of the application.
 * This will set the currentTime in the timeline-bar as well as tell Cesium what time the app is currently at.
 */
export const currentTimeChangeSlice = createSlice({
  name: "currentTimeChange",
  initialState,
  reducers: {
    /**
     * Overwrite the Current Time Change value or create one if none exists
     */
    setCurrentTimeChange(state, action: PayloadAction<CurrentTimeChangeState>) {
      if (state.currentTimeChange === action.payload.currentTimeChange) {
        set(state, "currentTimeChange", action.payload.currentTimeChange + 1);
      } else {
        set(state, "currentTimeChange", action.payload.currentTimeChange);
      }
    }
  }
});

export const { setCurrentTimeChange } = currentTimeChangeSlice.actions;

export const useCurrentTimeChangeSelector = createUseSliceSelector(
  currentTimeChangeSlice
);

export default currentTimeChangeSlice;

import { useEffect, useRef } from "react";

/**
 * Hook to invoke a callback on a periodic interval
 * @param callback - The function that will be run on a regular interval 
 * @param period - The interval period (length of time in ms) between callback invocations
 * @param delay - A deferred length of time (ms) before the recurring interval starts [default = 0]
 */
function useInterval(callback: () => void, period: number | null, delay: number = 0): void {
  const savedCallback: any = useRef();
  const intervalId: any = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if interval period length is not specified.
    if (period === null) {
      return;
    }

    setTimeout(() => {
      intervalId.current = setInterval(() => savedCallback.current(), period);
    }, delay)

    return () => clearInterval(intervalId.current);
  }, [period, delay]);
}

export default useInterval;

import ExerciseModel from "../@types/exercise.model";
import handleHttpError from "./handleHttpError";
import handleHttpResponse from "./handleHttpResponse";
import HttpsService from "./httpsService";

const ExerciseService = {
  createExercise: (exerciseData: ExerciseModel) => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.EXERCISE_SVC_BASE_URL}${process.env.API_URL}/exercise-svc`,
        data: exerciseData
      })
      .then((res) => handleHttpResponse(res, "Failed to get all exercises"))
      .catch((err) => handleHttpError(err, []));
  },
  verifyUniqueExerciseName: (name: string) => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.EXERCISE_SVC_BASE_URL}${process.env.API_URL}/exercise-svc/verifyUniqueName`,
        data: { name }
      })
      .then((res) => handleHttpResponse(res, "Failed to get all exercises"))
      .catch((err) => handleHttpError(err, []));
  },
  getAllExercises: () => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.EXERCISE_SVC_BASE_URL}${process.env.API_URL}/exercise-svc`
      })
      .then((res) => handleHttpResponse(res, "Failed to get all exercises"))
      .catch((err) => handleHttpError(err, []));
  },
  deleteExercise: (id: string) => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "delete",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.EXERCISE_SVC_BASE_URL}${process.env.API_URL}/exercise-svc/${id}`
      })
      .then((res) => handleHttpResponse(res, "Failed to get all exercises"))
      .catch((err) => handleHttpError(err, false));
  },
  getExerciseById: (id: string) => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.EXERCISE_SVC_BASE_URL}${process.env.API_URL}/exercise-svc/${id}`
      })
      .then((res) => handleHttpResponse(res, `Failed to get exercise ${id}`))
      .catch((err) => handleHttpError(err, []));
  },
  updateExercise: (exerciseData: ExerciseModel) => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "put",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.EXERCISE_SVC_BASE_URL}${process.env.API_URL}/exercise-svc/`,
        data: exerciseData
      })
      .then((res) =>
        handleHttpResponse(res, `Failed to put exercise ${exerciseData._id}`)
      )
      .catch((err) => handleHttpError(err, false));
  },
  renameExercise: (id: string, name: string) => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.EXERCISE_SVC_BASE_URL}${process.env.API_URL}/exercise-svc/rename/${id}`,
        data: { name }
      })
      .then((res) => handleHttpResponse(res, `Failed to rename exercise ${id}`))
      .catch((err) => handleHttpError(err, false));
  }
};

export default ExerciseService;

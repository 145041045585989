/**
 * Base efforts copied from JSIRO base_url.js functionality
 */

/**
 * Pulls path directory from browser location path
 */
export function getDirectory() {
  /**
   * Possible path options:
   *  /mvcr/workspace/6399cdd9ed39482fe503b520
   *  /workspace/6399cdd9ed39482fe503b520
   */
  let str = window.location.pathname || "";

  /**
   * Split path and retrieve initial chunk of url path
   * split examples:
   *  /mvcr/workspace/6399cdd9ed39482fe503b520 ==> [``,`mvcr`,`workspace`, `6399cdd9ed39482fe503b520`]
   *  /workspace/6399cdd9ed39482fe503b520 ==> [``,`workspace`, `6399cdd9ed39482fe503b520`]
   */
  const splitOnSlash = str.split("/");
  let $directory = splitOnSlash[1] || "";

  // Check to see if the $directory value is equal to any known sub paths which
  // will need to be ignored as they are not part of the base url construction
  const knownSubPaths = ["workspace", "reports"];
  if (knownSubPaths.includes($directory)) {
    return "";
  }

  return $directory ? `/${$directory}` : "";
}

/**
 * Pulls origin from browser location
 */
export function getOrigin() {
  let $path: any = window.location;
  // IE9 bug fix
  if ($path.origin === undefined) {
    $path.origin = $path.protocol + "//" + $path.host;
  }
  let $origin: string = $path.origin;
  return $origin;
}

/**
 * Builds base url from location origin and path
 */
export function getBaseUrl() {
  let $directory = getDirectory();
  let $origin = getOrigin();
  let baseUrl = $origin + $directory;
  return baseUrl;
}

export const apiHost = getBaseUrl();

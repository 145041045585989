import { mvcrApi } from ".";
import UserWorkspaceSettingsModel, {
  AddUserWorkspaceSettingsModel
} from "../../../@types/userSettings.model";
import { keycloak } from "../../../keycloak";
import { manualUserSettingsApiCacheUpdate } from "./user-settings.helper";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";

/**
 * Optimistically update the local cache of Workspaces on patch/put mutation
 */
async function optimisticUpdate(
  { usersettings }: { usersettings: Partial<UserWorkspaceSettingsModel> },
  {
    dispatch,
    queryFulfilled
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    queryFulfilled: PromiseWithKnownReason<unknown, unknown>;
  }
) {
  const patchResult = manualUserSettingsApiCacheUpdate(
    keycloak?.subject,
    usersettings
  );

  try {
    await queryFulfilled;
  } catch {
    console.error(`Update user settings failed. Rolling back the cache update`);
    patchResult.undo();
  }
}

export const userSettingsApi = mvcrApi.injectEndpoints({
  endpoints: (build) => ({
    // This endpoint does not provide any tags because every query should get
    // its own unique cache, as it is a search endpoint that only provides
    // a subset of fields
    getUserSettingsById: build.query<
      UserWorkspaceSettingsModel,
      {
        userId: string;
      }
    >({
      query: ({ userId }) => ({
        method: "get",
        url: `${process.env.ACCOUNT_SVC_BASE_URL}${process.env.API_URL}/account-svc/user-settings/${userId}`
      }),
      // Note: This could be further optimized by mapping to ids if the query includes them
      providesTags: (_result, _error, { userId }) => [
        { type: "User Settings", id: `${userId}` }
      ]
    }),
    getFavoriteFilterOrAreaCount: build.query<
      number,
      { id: string; type: "Areas" | "Filters" }
    >({
      query: ({ id, type }) => ({
        method: "get",
        url: `${process.env.ACCOUNT_SVC_BASE_URL}${process.env.API_URL}/account-svc/count/${type}/${id}`
      }),
      providesTags: (_result, _error) => [
        { type: "User Settings", id: keycloak?.subject }
      ]
    }),
    addUserSettings: build.mutation<
      AddUserWorkspaceSettingsModel,
      {
        usersettings: AddUserWorkspaceSettingsModel;
      }
    >({
      query: ({ usersettings }) => ({
        method: "POST",
        url: `${process.env.ACCOUNT_SVC_BASE_URL}${process.env.API_URL}/account-svc/user-settings`,
        data: usersettings
      }),
      invalidatesTags: (_result, _error) => [
        { type: "User Settings", id: keycloak?.subject }
      ]
    }),
    updateUserSettings: build.mutation<
      UserWorkspaceSettingsModel,
      {
        usersettings: UserWorkspaceSettingsModel;
      }
    >({
      query: ({ usersettings }) => ({
        method: "PUT",
        url: `${process.env.ACCOUNT_SVC_BASE_URL}${process.env.API_URL}/account-svc/user-settings`,
        data: usersettings
      }),
      onQueryStarted: optimisticUpdate
    }),
    patchUserSettings: build.mutation<
      Partial<UserWorkspaceSettingsModel>,
      {
        usersettings: Partial<UserWorkspaceSettingsModel>;
      }
    >({
      query: ({ usersettings }) => ({
        method: "PATCH",
        url: `${process.env.ACCOUNT_SVC_BASE_URL}${process.env.API_URL}/account-svc/user-settings`,
        data: usersettings
      }),
      onQueryStarted: optimisticUpdate
    })
  })
});

export const {
  useGetUserSettingsByIdQuery,
  useGetFavoriteFilterOrAreaCountQuery,
  useAddUserSettingsMutation,
  useUpdateUserSettingsMutation,
  usePatchUserSettingsMutation
} = userSettingsApi;

export default userSettingsApi;

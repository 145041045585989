import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionTypes } from "services/dataVolumeService/types";
import { createUseSliceSelector } from "state/util";

export interface ConnectionStatusState {
  messageBusSocketStatus: ConnectionTypes;
}

const initialState: ConnectionStatusState = {
  messageBusSocketStatus: null
};

export interface ConnectionStatusPayload {
  targetConnection: keyof ConnectionStatusState;
  updatedConnectionStatus: ConnectionTypes;
}

export const connectionStatusSlice = createSlice({
  name: "connectionStatus",
  initialState,
  reducers: {
    setConnectionStatus(state, action: PayloadAction<ConnectionStatusPayload>) {
      const { targetConnection, updatedConnectionStatus } = action.payload;
      state[targetConnection] = updatedConnectionStatus;
    }
  }
});

export const { setConnectionStatus } = connectionStatusSlice.actions;

export const useConnectionStatusSelector = createUseSliceSelector(
  connectionStatusSlice
);

export default connectionStatusSlice;

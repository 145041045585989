import React, { ReactNode } from "react";

interface ILoadingOverlayContextState {
  type?: string;
  dataToBeLoaded: Boolean[];
  dispatch: (state: Partial<ILoadingOverlayContextState>) => void;
  fetchingMsg?: String;
  errorMsg?: String
}

const initialState: ILoadingOverlayContextState = {
  dataToBeLoaded: [],
  fetchingMsg: null,
  errorMsg: null,
  dispatch: () => {
    /*no-op*/
  }
};

const LoadingOverlayContext =
  React.createContext<ILoadingOverlayContextState>(initialState);

const loadingOverlayReducer = (
  state: any,
  action: ILoadingOverlayContextState
): ILoadingOverlayContextState => {
  switch (action.type) {
    case "CLEAR_LOADING_DATA": {
      return {
        ...state, 
        dataToBeLoaded: [], 
        fetchingMsg: null,
        errorMsg: null
      };
    }
    case "SET_LOADING_DATA": {
      return {
        ...state,
        dataToBeLoaded: action.dataToBeLoaded,
        fetchingMsg: action.fetchingMsg,
      };
    }
    case "SET_LOADING_ERROR": {
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    }
  }
};

const LoadingOverlayProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [state, dispatch] = React.useReducer(
    loadingOverlayReducer,
    initialState
  );
  const value = { ...state, dispatch };
  return (
    <LoadingOverlayContext.Provider value={value}>
      {children}
    </LoadingOverlayContext.Provider>
  );
};

const useLoadingOverlayContext = () => {
  const context = React.useContext(LoadingOverlayContext);
  return context;
};

const useUpdateLoadingOverlayContext = ({
  dataToBeLoaded
}: ILoadingOverlayContextState) => {
  const context = React.useContext(LoadingOverlayContext);
  return (context.dataToBeLoaded = dataToBeLoaded);
};

export {
  LoadingOverlayContext,
  LoadingOverlayProvider,
  useLoadingOverlayContext,
  useUpdateLoadingOverlayContext
};

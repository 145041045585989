import { useEffect, useRef, useState } from "react";
import App from "./App";
import { useGetAppConfigQuery } from "state/api/mvcrApi/data-config";
import { useKeycloakSelector } from "state/slices/keycloak";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppDispatch } from "state";
import { setAppConfig } from "state/slices/ui-settings";
import { BaseApplicationConfigModel } from "./@types/data-config.model";
import { buildAppConfig } from "util/app-config-map";
import { useLoadingOverlayContext } from "context/loading-overlay-context";

/**
 * Wraps the App component so that additional application configuration
 * can be performed prior to App loading.
 */
const AppWrapper: React.FC = () => {
  /**
   * Component depicting either a loading screen while app config is pulled
   * from the database, an error screen if database pull fails, or
   * move into the normal App component after the app config has been
   * successfully retrieved
   */
  const [displayState, setDisplayState] = useState<JSX.Element>(null);
  const loadingOverlayState = useLoadingOverlayContext();
  const loadingOverlayDispatch = loadingOverlayState.dispatch;

  // Add a ref for tracking when the app config is retrieved to prevent additional re-renders when keycloak updates
  const configLoadedRef = useRef<boolean>(false);

  /**
   * Get the dispatch function for updating Redux
   */
  const dispatch = useAppDispatch();

  /**
   * Pull in keycloak state for Keycloak redux selector
   */
  const keycloakState = useKeycloakSelector((state) => state);
  /**
   * Pull application configuration from database using RTK query
   * when Keycloak user becomes available. Skip performing the query
   * until Keycloak user is available as the endpoint is protected
   */
  const { data: appConfigData, error: appConfigError } = useGetAppConfigQuery(
    keycloakState?.user ? undefined : skipToken
  );

  useEffect(() => {
    /**
     * We only want to proceed with displaying the loading indicator and
     * setting the app config if the user is authenticated.
     */
    if (!keycloakState?.user || configLoadedRef.current) return;

    /**
     * If the app config pull results in an error, the application cannot
     * be setup and it will not function as expected. Therefore, an error
     * page is displayed telling the user to refresh that page or to
     * contact their system administrator.
     */
    if (appConfigError) {
      console.error(`Failed to resolve application configs`, appConfigError);
      loadingOverlayDispatch({
        type: "SET_LOADING_ERROR",
        errorMsg: `The application configuration could not be loaded, please try
          refreshing the page. If the issue persists, please contact your system
          administrator.`
      });
      return;
    }

    /**
     * On successful pull of the app config, store the config and
     * continue on into the application
     */
    if (appConfigData) {
      console.log(`Resolved request for application configs.`);
      const appConfig: BaseApplicationConfigModel =
        buildAppConfig(appConfigData);

      dispatch(
        setAppConfig({
          appConfig
        })
      );

      configLoadedRef.current = true;
      loadingOverlayDispatch({
        type: "CLEAR_LOADING_DATA"
      });
      setDisplayState(<App />);
      return;
    }

    /**
     * If we haven't received the application config, display loading indicator
     */
    loadingOverlayDispatch({
      type: "SET_LOADING_DATA",
      dataToBeLoaded: [false],
      fetchingMsg: "Retrieving app configuration..."
    });
  }, [
    keycloakState?.user,
    appConfigData,
    appConfigError,
    loadingOverlayDispatch,
    dispatch
  ]);

  return displayState;
};

export default AppWrapper;

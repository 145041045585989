import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UploadFile } from "../../@types/uploadFileEntries.model";
import { createUseSliceSelector } from "../util";

/**
 * Stored uploadFiles that the user is editing.
 * These values are intended to override the currently cached
 * uploadFiles values.
 */
export interface UploadFileEntriesState {
  uploadFiles: UploadFile[];
}

/**
 * sets the state of the slice to be an empty array.
 */
const initialState: UploadFileEntriesState = {
  uploadFiles: []
};

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const uploadFileEntriesSlice = createSlice({
  name: "upload-file-entries",
  initialState,
  reducers: {
    /**
     * Create an uploadFile value.
     * @param state is the current state of the slice
     * @param action is the action to take place (create, update, delete)
     */
    createUploadFileEntry(state, action: PayloadAction<UploadFile>) {
      if (action?.payload) {
        const stateUploadFiles = state.uploadFiles.filter(
          (uf) => uf.id !== action.payload.id
        );
        state.uploadFiles = [...stateUploadFiles, action.payload];
      }
    },
    /**
     * Delete the current uploadFile value.
     * @param state is the current state of the slice
     * @param action is the action to take place (create, update, delete)
     */
    deleteUploadFileEntry(state, action: PayloadAction<UploadFile>) {
      if (action?.payload)
        state.uploadFiles = state.uploadFiles.filter(
          (pf) => pf.id !== action.payload.id
        );
    }
  }
});

export const { createUploadFileEntry, deleteUploadFileEntry } =
  uploadFileEntriesSlice.actions;

export const useUploadFileEntriesSelector = createUseSliceSelector(
  uploadFileEntriesSlice
);

export default uploadFileEntriesSlice;

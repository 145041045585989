import { UserListModel } from "../../../@types/userList.model";
import { mvcrApi } from ".";

/**
 * Api to retrive the list of users from account-svc which gets
 * its list from keycloak, can be extended for other endpoints
 */
export const accountServiceApi = mvcrApi.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query<UserListModel[], void>({
      query: () => {
        return {
          method: "get",
          url: `${process.env.ACCOUNT_SVC_BASE_URL}${process.env.API_URL}/account-svc/users`
        };
      },
      providesTags: () => [{ type: "Users" }]
    }),
    getAccountInfo: build.query<void, void>({
      query: () => ({
        method: "get",
        url: `${process.env.ACCOUNT_SVC_BASE_URL}${process.env.API_URL}/account-svc/login`
      }),
      providesTags: () => [{ type: "Users" }]
    })
  })
});

export const { useGetAllUsersQuery, useGetAccountInfoQuery } =
  accountServiceApi;

export default accountServiceApi;

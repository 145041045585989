import { Fragment } from "react";
import "./security-banner.scss";

export enum SecurityLevel {
  UNCLASSIFIED = "UNCLASSIFIED",
  CONFIDENTIAL = "CONFIDENTIAL",
  SECRET = "SECRET",
  TOP_SECRET = "TOP SECRET"
}

export interface SecurityBannerProps {
  securityLevel?: SecurityLevel;
}

const SecurityBanner: React.FC<SecurityBannerProps> = ({ securityLevel }) => {
  const getClass = (): string => {
    const sl = securityLevel.trim().toLowerCase();
    const classNames = [
      "security-banner",
      "alert",
      "alert-message",
      "alert-danger"
    ];

    if (sl.startsWith("unclass")) {
      classNames.push("unclass");
    } else if (sl.startsWith("confidential")) {
      classNames.push("confidential");
    } else if (sl.startsWith("secret")) {
      classNames.push("secret");
    } else if (sl.startsWith("top")) {
      classNames.push("ts");
    }

    return classNames.join(" ");
  };

  return (
    <Fragment>
      <div className={getClass() + " upper"}>
        This page contains dynamic content -- Highest Possible Content is{" "}
        {securityLevel}
      </div>
      <div className={getClass() + " lower"} id="security-banner-bottom">
        This page contains dynamic content -- Highest Possible Content is{" "}
        {securityLevel}
      </div>
    </Fragment>
  );
};

export default SecurityBanner;

import { store } from "state";
import UserWorkspaceSettingsModel from "../../../@types/userSettings.model";
import userSettingsApi from "./user-settings";

/**
 * Perform a manual cache update of `Workspace->getWorkspaceById->{workspaceId}`
 * @param workspaceId {string} - Workspace ID to update
 * @param patch {Partial<WorkspaceModel>} - WorkspaceModel object with the patches to apply
 * @returns PatchCollection {PatchCollection}
 */
export const manualUserSettingsApiCacheUpdate = (
    userId: string,
    patch: Partial<UserWorkspaceSettingsModel>
) => {
    return store.dispatch(
        userSettingsApi.util.updateQueryData(
            "getUserSettingsById",
            { userId: userId },
            (draft) => {
                Object.assign(draft, patch);
            }
        )
    );
};

import handleHttpError from "./handleHttpError";
import handleHttpResponse from "./handleHttpResponse";
import HttpsService from "./httpsService";

const DataConfigService = {
  getDefaultMapIcons: () => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=defaultIcons`
      })
      .then((res: any) =>
        handleHttpResponse(res, "Failed to get default icons")
      )
      .catch((err: any) => handleHttpError(err, []));
  },
};

export default DataConfigService;

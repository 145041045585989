import { EuiConfirmModal, EuiConfirmModalProps, EuiProvider } from "@elastic/eui";
import { noop } from "lodash";
import { FC } from "react";

const ConfirmationModal: FC<EuiConfirmModalProps> = (props) => {
  //initializing here sets default values
  const {
    confirmButtonText = "OK",
    cancelButtonText = "Cancel",
    buttonColor = "danger",
    onConfirm = noop,
    onCancel = noop,
    ...rest
  } = props;

  props = {
    confirmButtonText,
    cancelButtonText,
    buttonColor,
    onConfirm,
    onCancel,
    ...rest
  };
  return (
    <EuiProvider colorMode="dark">
      <EuiConfirmModal {...props} />
    </EuiProvider>
  );
};

export default ConfirmationModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { createUseSliceSelector } from "../util";

/**
 * Stored ops clock comparison that the user is editing.
 * These values are intended to override the currently cached
 * ops clock comparison's values.
 * @prop {number} count is the number that the ops clock comparison is assigned to.
 * @prop {string} name is the name of the ops clock comparison
 */
export interface OpsClockComparisonState {
  count: number;
  name: string;
}

/**
 * Maps opsClockComparisonId -> OpsClockComparisonState
 * @prop {string, OpsClockComparisonState} Record is the record of the ops clock comparison states
 */
export type OpsClockComparisonStates = Record<string, OpsClockComparisonState>;

/**
 * @prop {string} opsClockComparisonId is the ID of the created Ops Clock Comparison
 */
export interface KeyInterface {
  opsClockComparisonId: string;
}

/**
 * @prop {OpsClockComparisonState} opsClockComparison the state of the Ops Clock Comparison
 */
export interface OpsClockComparisonPayload {
  opsClockComparison: OpsClockComparisonState;
}

/**
 * @prop {partial<OpsClockComparisonState>} opsClockComparison allows the Ops Clock Comparison to be called using just the name
 */
export interface PartialOpsClockComparisonPayload {
  opsClockComparison: Partial<OpsClockComparisonState>;
}

/**
 * sets the state of the slice to be an empty object.
 */
const initialState: OpsClockComparisonStates = {};

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const opsClockComparisonSlice = createSlice({
  name: "opsClockComparison",
  initialState,
  reducers: {
    /**
     * Overwrite the working ops clock comparison value, or create one in state if it doesn't exist.
     * @param {state} state is the current state of the slice
     * @param {PayloadAction} action is the action to take place (create, update, delete)
     */
    setWorkingOpsClockComparison(
      state,
      action: PayloadAction<KeyInterface & OpsClockComparisonPayload>
    ) {
      const { opsClockComparisonId, opsClockComparison } = action.payload;
      set(
        state,
        `opsClockComparison.${opsClockComparisonId}`,
        opsClockComparison
      );
    },
    /**
     *
     * Update working ops clock comparison values. A value will only be changed if it is defined in the payload.
     * Will do nothing if the ops clock comparison does not exist.
     * @param {state} state is the current state of the slice
     * @param {PayloadAction} action is the action to take place (create, update, delete)
     */
    updateWorkingOpsClockComparison(
      state,
      action: PayloadAction<KeyInterface & PartialOpsClockComparisonPayload>
    ) {
      const { opsClockComparisonId, opsClockComparison } = action.payload;
      if (state?.opsClockComparison?.[opsClockComparisonId] === undefined) {
        console.error(
          `Ops Clock ${opsClockComparisonId} was never initialized in the store.`
        );
        return;
      }
      const tempOpsClockComparison = {
        ...state.opsClockComparison,
        [opsClockComparisonId]: {
          ...state.opsClockComparison[opsClockComparisonId],
          ...opsClockComparison
        }
      };
      set(state, "opsClockComparison", tempOpsClockComparison);
    },
    /**
     * function to delete an ops clock comparison from the store.
     * @param {state} state is the current state of the slice
     * @param {PayloadAction} action is the action to take place (create, update, delete)
     */
    removeOpsClockComparison(
      state,
      action: PayloadAction<KeyInterface & PartialOpsClockComparisonPayload>
    ) {
      const { opsClockComparisonId } = action.payload;
      delete state.opsClockComparison[opsClockComparisonId];
    }
  }
});

export const {
  setWorkingOpsClockComparison,
  updateWorkingOpsClockComparison,
  removeOpsClockComparison
} = opsClockComparisonSlice.actions;

export const useOpsClockComparisonSelector = createUseSliceSelector(
  opsClockComparisonSlice
);

export default opsClockComparisonSlice;

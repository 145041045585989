import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUseSliceSelector } from "../util";
import { DataJob, JobHash } from "./data-volume";
import { RootState } from "state";
import {
  CapabilityComparisonDataSet,
  CapabilityComparisonRequestParams
} from "../../@types/capability-comparison.model";
import { createCapabilityComparisonJobHash } from "util/create-capability-comparison-job-hash";

export interface KeyInterface {
  capabilityComparisonId: string;
}

export type CapabilityComparisonData = CapabilityComparisonDataSet;

export type CapabilityComparisonDataStore = Record<
  string,
  CapabilityComparisonData
>;

export interface CapabilityComparisonDataPayload {
  jobHash: DataJob["jobHash"];
  capabilityComparisonData: CapabilityComparisonData;
}

export type CapabilityComparisonOptions = CapabilityComparisonRequestParams & {
  jobHash?: string;
  elnots: string[];
  cenots: string[];
};

/**
 * Maps CapabilityComparisonId -> CapabilityComparisonOptions
 */
export type CapabilityComparisonOptionStore = Record<
  string,
  CapabilityComparisonOptions
>;

/**
 * @prop {ChartOption} chartOption the state of the chart option
 */
export interface CapabilityComparisonOptionsPayload extends KeyInterface {
  capabilityComparisonOptions: CapabilityComparisonOptions;
}

export type CapabilityComparisonJob = Pick<
  DataJob,
  "label" | "jobHash" | "status" | "message"
> & {
  capabilityComparisonParams: CapabilityComparisonRequestParams;
};

export type CapabilityComparisonJobs = {
  [key: JobHash]: CapabilityComparisonJob;
};

export type UpdateCapabilityComparisonJobsType = Pick<DataJob, "jobHash"> &
  Partial<Pick<DataJob, "status" | "message">>;

export interface CapabilityComparisonState {
  capabilityComparisonData: CapabilityComparisonDataStore;
  capabilityComparisonJobs: CapabilityComparisonJobs;
  capabilityComparisonOptions: CapabilityComparisonOptionStore;
}

/**
 * Sets the state of the slice
 */
const initialState: CapabilityComparisonState = {
  capabilityComparisonData: {},
  capabilityComparisonJobs: {},
  capabilityComparisonOptions: {}
};

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const capabilityComparisonSlice = createSlice({
  name: "capabilityComparisonState",
  initialState,
  reducers: {
    /**
     * Adds a job to capabilityComparisonJobs
     */
    addCapabilityComparisonJob(
      state,
      action: PayloadAction<CapabilityComparisonJob>
    ) {
      state.capabilityComparisonJobs[action.payload.jobHash] = action.payload;
    },

    /**
     * Updates the job status with the message bus status update
     */
    updateCapabilityComparisonJob(
      state,
      action: PayloadAction<UpdateCapabilityComparisonJobsType>
    ) {
      const { jobHash } = action.payload;
      state.capabilityComparisonJobs[jobHash] = {
        ...state.capabilityComparisonJobs[jobHash],
        ...action.payload
      };
    },

    /**
     * Removes specified job from capabilityComparisonJobs state
     */
    deleteCapabilityComparisonJob(state, action: PayloadAction<JobHash>) {
      delete state.capabilityComparisonJobs[action.payload];
    },

    /**
     * Add capability comparison data
     */
    addCapabilityComparisonData(
      state,
      action: PayloadAction<CapabilityComparisonDataPayload>
    ) {
      const { jobHash, capabilityComparisonData } = action.payload;
      state.capabilityComparisonData[jobHash] = capabilityComparisonData;
    },

    /**
     *
     * Update capability comparison data values. A value will only be changed if it is defined in the payload.
     * Will do nothing if the capability comparison data does not exist.
     */
    updateCapabilityComparisonData(
      state,
      action: PayloadAction<CapabilityComparisonDataPayload>
    ) {
      const { jobHash, capabilityComparisonData } = action.payload;
      if (state?.capabilityComparisonData?.[jobHash] === undefined) {
        console.error(
          `Capability Comparison ${jobHash} was never initialized in the store.`
        );
        return;
      }
      state.capabilityComparisonData[jobHash] = capabilityComparisonData;
    },

    /**
     * function to delete a capability comparison data from the store.
     */
    deleteCapabilityComparisonData(
      state,
      action: PayloadAction<{ jobHash: string }>
    ) {
      const { jobHash } = action.payload;
      delete state.capabilityComparisonData[jobHash];
    },

    /**
     * Add capability comparison options
     */
    addCapabilityComparisonOptions(
      state,
      action: PayloadAction<CapabilityComparisonOptionsPayload>
    ) {
      const { capabilityComparisonId, capabilityComparisonOptions } =
        action.payload;

      // Determine the job hash for this open set and store it along with the options
      const jobHash = createCapabilityComparisonJobHash({
        receivers: capabilityComparisonOptions.receivers,
        jammers: capabilityComparisonOptions.jammers
      });

      state.capabilityComparisonOptions[capabilityComparisonId] = {
        ...capabilityComparisonOptions,
        jobHash
      };
    },

    /**
     * Update capability comparison option values. A value will only be changed if it is defined in the payload.
     * Will do nothing if the capability comparison option id does not exist.
     */
    updateCapabilityComparisonOptions(
      state,
      action: PayloadAction<CapabilityComparisonOptionsPayload>
    ) {
      const { capabilityComparisonId, capabilityComparisonOptions } =
        action.payload;
      if (
        state?.capabilityComparisonOptions?.[capabilityComparisonId] ===
        undefined
      ) {
        console.error(
          `Capability Comparison Option ${capabilityComparisonId} was never initialized in the store.`
        );
        return;
      }

      // Determine the job hash for this open set and store it along with the options
      const jobHash = createCapabilityComparisonJobHash({
        receivers: capabilityComparisonOptions.receivers,
        jammers: capabilityComparisonOptions.jammers
      });

      state.capabilityComparisonOptions[capabilityComparisonId] = {
        ...capabilityComparisonOptions,
        jobHash
      };
    },

    /**
     * function to delete capability comparison options from the store.
     */
    deleteCapabilityComparisonOptions(
      state,
      action: PayloadAction<KeyInterface>
    ) {
      const { capabilityComparisonId } = action.payload;

      // Get the related jobHash so we can check and remove the data if it's only related to this option set
      const { jobHash } =
        state.capabilityComparisonOptions[capabilityComparisonId];
      let numberOfOtherOptionsWithHash = 0;
      for (const loopedComparisonId in state.capabilityComparisonOptions) {
        if (loopedComparisonId === capabilityComparisonId) {
          continue;
        }
        if (
          state.capabilityComparisonOptions[loopedComparisonId].jobHash ===
          jobHash
        ) {
          numberOfOtherOptionsWithHash++;
        }
      }

      if (!numberOfOtherOptionsWithHash) {
        delete state.capabilityComparisonData[jobHash];
        delete state.capabilityComparisonJobs[jobHash];
      }

      delete state.capabilityComparisonOptions[capabilityComparisonId];
    }
  }
});

export const {
  addCapabilityComparisonJob,
  updateCapabilityComparisonJob,
  deleteCapabilityComparisonJob,
  addCapabilityComparisonData,
  updateCapabilityComparisonData,
  deleteCapabilityComparisonData,
  addCapabilityComparisonOptions,
  updateCapabilityComparisonOptions,
  deleteCapabilityComparisonOptions
} = capabilityComparisonSlice.actions;

export const useCapabilityComparisonStateSelector = createUseSliceSelector(
  capabilityComparisonSlice
);

export const useCapabilityComparisonDataVolumeJobsSelector = (
  state: RootState
) => state.capabilityComparisonState.capabilityComparisonJobs;

/**
 * Provides the current list of IN-PROGRESS/SUBMITTED jobs.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const inProgressCapabilityComparisonJobsSelector = createSelector(
  useCapabilityComparisonDataVolumeJobsSelector,
  (dataJobs) =>
    Object.values(dataJobs).filter(
      (job) => job.status === "IN-PROGRESS" || job.status === "SUBMITTED"
    )
);

export default capabilityComparisonSlice;

import { EuiColorPalettePickerPaletteProps } from "@elastic/eui";
import { mvcrApi } from ".";
import {
  BaseApplicationConfigModel,
  DataConfigModel,
  DefaultIconsDefinition,
  MetaLayerLabelModel
} from "../../../@types/data-config.model";
import UISettings from "../../../@types/uiSettings.model";
import {
  CCMDConfigModel,
  CountriesConfigModel
} from "../../../@types/data-configs/ccmd-config.model";
import { DataSourceGroupsModel } from "../../../@types/data-configs/data-source-group-config.model";
import { DataSourceBadgeModel } from "../../../@types/data-configs/data-source-badge.model";
import { TimeZoneModel } from "../../../@types/timeZone.model";
import { TooltipModel } from "../../../@types/tooltips.model";
import { AvailableToursModel } from "../../../@types/tour.model";
import { AffiliationColorsModel } from "../../../@types/data-configs/affiliation-colors.model";
import {
  PermissionHierarchyDefinition,
  PermissionHierarchyTarget,
  WorkspacePermissionDefinition
} from "../../../@types/permission.model";
import { EMITrendsUIConfig } from "../../../@types/emiTrendsUiConfig.model";
import {
  CommonDataLayerModel,
  CapabilityGroupListModel,
  DataSourceLookupModel,
  DateRangePreset
} from "@mvcr/mvcr-common";
import { ConfigurableMenuProps } from "components/configurable-menu/ConfigurableMenu";
import { ToggleOption } from "components/toggles/toggle";
import { EmbmIconographyModel } from "../../../@types/data-configs/iconography.model";

/**
 * This API is still a WIP and does not contain all endpoints
 * available in services/dataConfigService.ts.
 *
 * It was created, incomplete, to move palettes over to rtk-query.
 */
export const dataConfigApi = mvcrApi.injectEndpoints({
  endpoints: (build) => ({
    getChartPalettes: build.query<EuiColorPalettePickerPaletteProps[], void>({
      query: () => ({
        method: "get",
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=chartPalettes`
      }),
      transformResponse: (response) => response?.payload
    }),
    getUiSettings: build.query<DataConfigModel<UISettings[]>, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=mvcr-ui`
      }),
      transformResponse: (response) => response?.payload
    }),
    getEMITrendsUIConfig: build.query<
      DataConfigModel<EMITrendsUIConfig[]>,
      void
    >({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=emiTrendsUiConfig`
      }),
      transformResponse: (response) => response?.payload
    }),
    getEMITrendsDashboardConfig: build.query<any[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=emiTrendsDashboardConfig`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return CCMD options from data-cfg-svc
     */
    getAllCCMD: build.query<CCMDConfigModel[], void>({
      query: () => ({
        method: "get",
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=ccmd`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return countries options from data-cfg-svc
     */
    getAllCountries: build.query<CountriesConfigModel[], void>({
      query: () => ({
        method: "get",
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=countries`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return water body options from data-cfg-svc
     */
    getAllWaterBodies: build.query<any, void>({
      query: () => ({
        method: "get",
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=waterBodies`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return all data source groups from data-cfg-svc
     */
    getAllDataSourceGroups: build.query<DataSourceGroupsModel[], void>({
      query: () => ({
        method: "get",
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=datasourceGroups`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return data source badge configurations from data-cfg-svc
     */
    getDataSourceBadges: build.query<DataSourceBadgeModel, void>({
      query: () => ({
        method: "get",
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=datasourceBadges`
      }),
      transformResponse: (response) => response?.payload?.[0] || {}
    }),
    /**
     * GET request to return all data source headers from data-cfg-svc
     */
    getAllDataSourceHeaders: build.query<MetaLayerLabelModel[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=metaLayerLabels`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return all data source lookups from data-cfg-svc
     */
    getAllDataSourceLookups: build.query<DataSourceLookupModel[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=datasourceLookups`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return all data source lookups from data-cfg-svc
     */
    getTimeZones: build.query<TimeZoneModel[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=timeZones`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return all buffer zone options for pre-defined shapes (ccmd/country/water-body) from data-cfg-svc
     */
    getBufferOptions: build.query<ToggleOption[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=bufferZoneOptions`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return minimum data fields from data-cfg-svc
     */
    getMinimumDataFields: build.query<(keyof CommonDataLayerModel)[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=minimumDataFields`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * Get request to retrieve the workspace permissions configuration
     */
    getWorkspacePermissions: build.query<WorkspacePermissionDefinition, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc/workspace-permissions`
      }),
      providesTags: (_result, _error) => [{ type: "Workspace Permissions" }]
    }),
    /**
     * GET request to retrieve the permissions hierarchy fort the given target
     */
    getPermissionHierarchy: build.query<
      PermissionHierarchyDefinition,
      { target: PermissionHierarchyTarget }
    >({
      query: ({ target }) => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc/permission-hierarchy?target=${target}`
      }),
      providesTags: (_result, _error, { target }) => [
        { type: "Workspace Hierarchy", id: `${target}` }
      ]
    }),
    getUnauthorizedAccessContent: build.query<any, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc/unauthorized-access-content`
      }),
      transformResponse: (response) => response?.payload
    }),
    getAppConfig: build.query<BaseApplicationConfigModel, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc/app-config`
      }),
      transformResponse: (response) => response
    }),
    /**
     * GET request to return all tooltips from data-cfg-svc
     */
    getTooltips: build.query<TooltipModel, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=tooltips`
      }),
      transformResponse: (response) => response?.payload?.[0] || {}
    }),
    /**
     * GET request to return all tours from data-cfg-svc
     */
    getTour: build.query<AvailableToursModel, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=tours`
      }),
      transformResponse: (response) => response?.payload?.[0] || {}
    }),
    /**
     * GET request to return capability-service mapping from data-cfg-svc
     */
    getCapabilityServiceMapping: build.query<CapabilityGroupListModel, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=capabilityServiceMapping`
      }),
      transformResponse: (response) => response?.payload?.[0] || {}
    }),
    /**
     * GET request to return all default icons from data-cfg-svc
     */
    getDefaultIcons: build.query<DefaultIconsDefinition[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=defaultIcons`
      }),
      transformResponse: (response) => response?.payload || []
    }),
    /**
     * GET request to return configurable menu configs from data-cfg-svc
     */
    getConfigurableMenuSettings: build.query<ConfigurableMenuProps[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=configurableMenus`
      }),
      transformResponse: (response) => response?.payload || []
    }),
    /**
     * GET request to return affiliation colors from data-cfg-svc
     */
    getAffiliationColors: build.query<AffiliationColorsModel, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=affiliationColors`
      }),
      transformResponse: (response) => response?.payload?.[0] || {}
    }),
    /**
     * GET request to return all goat report headers from data-cfg-svc
     */
    getGoatReportTableHeaders: build.query<MetaLayerLabelModel[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=goatMetaLabels`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return all timeline presets
     */
    getTimelineDateRangePresets: build.query<DateRangePreset[], void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=timelineDateRangePresets`
      }),
      transformResponse: (response) => response?.payload
    }),
    /**
     * GET request to return all EMBM icons used to represent data sources from data-cfg-svc
     */
    getEmbmIconography: build.query<EmbmIconographyModel, void>({
      query: () => ({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_CFG_SVC_BASE_URL}${process.env.API_URL}/data-cfg-svc?configType=iconography`
      }),
      transformResponse: (response) => response?.payload?.[0] || {}
    })
  })
});

export const {
  useGetAllCCMDQuery,
  useGetAllCountriesQuery,
  useGetAllDataSourceGroupsQuery,
  useGetAllDataSourceHeadersQuery,
  useGetAllDataSourceLookupsQuery,
  useGetAllWaterBodiesQuery,
  useGetAppConfigQuery,
  useGetBufferOptionsQuery,
  useGetCapabilityServiceMappingQuery,
  useGetChartPalettesQuery,
  useGetDataSourceBadgesQuery,
  useGetEMITrendsDashboardConfigQuery,
  useGetEMITrendsUIConfigQuery,
  useGetMinimumDataFieldsQuery,
  useGetPermissionHierarchyQuery,
  useGetTimeZonesQuery,
  useGetTooltipsQuery,
  useGetTourQuery,
  useGetUiSettingsQuery,
  useGetUnauthorizedAccessContentQuery,
  useGetWorkspacePermissionsQuery,
  useGetDefaultIconsQuery,
  useGetConfigurableMenuSettingsQuery,
  useGetAffiliationColorsQuery,
  useGetGoatReportTableHeadersQuery,
  useGetTimelineDateRangePresetsQuery,
  useGetEmbmIconographyQuery
} = dataConfigApi;

export default dataConfigApi;

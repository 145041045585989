import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUseSliceSelector } from "../util";

export interface LoadingProgressCounterState {
  type: string;
  loadingProgressCount: number;
}

const initialState: LoadingProgressCounterState = {
  type: "",
  loadingProgressCount: 0
};

export interface LoadingProgressCounterPayload {
  type: string;
}

export const loadingProgressCounterSlice = createSlice({
  name: "loadingProgressCounter",
  initialState,
  reducers: {
    //increment or decrement the existing count value
    setLoadingProgressCounter(
      state,
      action: PayloadAction<LoadingProgressCounterPayload>
    ) {
      switch (action.payload.type) {
        case "INCREMENT":
          return {
            ...state,
            loadingProgressCount: state.loadingProgressCount + 1
          };
        case "DECREMENT":
          return {
            ...state,
            loadingProgressCount: state.loadingProgressCount - 1
          };
        default:
          return state;
      }
    }
  }
});

export const { setLoadingProgressCounter } =
  loadingProgressCounterSlice.actions;

export const useLoadingProgressCountSelector = createUseSliceSelector(
  loadingProgressCounterSlice
);

export default loadingProgressCounterSlice;

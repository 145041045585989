/**
 * User state
 */
import { KeycloakProfile, KeycloakTokenParsed } from "keycloak-js";
import React, { lazy, ReactNode, Suspense } from "react";

const LoadingPage = lazy(
  () => import(/* webpackChunkName: "LoadingPage" */ "../pages/loading")
);

const UnauthorizedPage = lazy(
  () =>
    import(/* webpackChunkName: "UnauthorizedPage" */ "../pages/unauthorized")
);
/**
 * The interface of the auth state
 *
 * @property {string} token - The keycloak token
 * @property {boolean} initialized - Has the token ever been set before. Useful for taking actions once there is a token value
 * @property {string | undefined} message - Optional. Allows for a message to be displayed if a user does not have access
 * @property {string | string[] | undefined} roles - Use the access role name(s) from the Keycloak server. Grants those names access to this component's children components.
 */
export type UserContextState = {
  type?: string;
  user?: KeycloakTokenParsed;
  token?: string;
  initialized?: boolean;
  profile?: KeycloakProfile;
  dispatch: Function;
};

const initialState: UserContextState = {
  token: undefined,
  initialized: false,
  user: undefined,
  dispatch: () => {
    /*no-op*/
  }
};

const UserContext = React.createContext<UserContextState>(initialState);

const userReducer = (state: any, action: UserContextState) => {
  switch (action.type) {
    case "CLEAR_TOKEN": {
      return { ...state, token: undefined, initialized: false };
    }
    case "SET_USER_TOKEN": {
      return {
        ...state,
        user: action.user,
        profile: action.profile,
        token: action.token,
        initialized: true
      };
    }
  }
};

const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  const value = { ...state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

// Wrap any elements that you want protected, pass roles and message through props
const AuthWall: React.FC<{
  children: ReactNode;
  roles: string | any[];
  acknowledged: boolean;
}> = ({ children, roles, acknowledged }) => {
  const DoDSplashPage = lazy(
    () =>
      import(
        /* webpackChunkName: "DoDSplashPage" */ "../components/dod-splash-page"
      )
  );
  const state = React.useContext<any>(UserContext);
  const access =
    state?.user?.realm_access?.roles &&
    Array.isArray(state.user.realm_access.roles) &&
    state.user.realm_access.roles.length > 0
      ? state.user.realm_access.roles
      : [];
  const granted =
    roles && typeof roles === "string"
      ? roles && access.includes(roles)
      : roles &&
        Array.isArray(roles) &&
        access.some((role_string: any) => roles.includes(role_string));

  if (!state?.initialized) {
    return (
      <Suspense fallback={<div />}>
        <LoadingPage />
      </Suspense>
    );
  }
  if (!acknowledged && granted) {
    return (
      <Suspense fallback={<div />}>
        <DoDSplashPage />
      </Suspense>
    );
  }
  if (acknowledged && granted) {
    return <>{children}</>;
  }
  return (
    <Suspense fallback={<div />}>
      <UnauthorizedPage />
    </Suspense>
  );
};

const useUser = () => {
  const context = React.useContext(UserContext);
  return context;
};

export { UserContext, useUser, UserProvider, AuthWall };

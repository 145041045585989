import { getBaseUrl } from './base-url';

/**
 * Combines a series of parameters into a URL with awareness of the '/' character. Should
 * return a properly combined string without any double '/'.
 * @param fragments
 * @returns
 */
export function combineUrlFragments(...fragments: string[]): string {
  return fragments.reduce((url, nextFragment) => {
    let newUrl = url;


    if (nextFragment !== undefined && nextFragment !== null) {
      /**
       * If nextFragment starts with http and newUrl contains no accumulated
       * results, return the nextFragment for this reduce iteration.
       * This prevents a http(s) address from being returned with a starting
       * `/` appended to the start of the http address:
       *    combineUrlFragments(`https://dev.expr.net`,`mvcr`) => `/https://dev.expr.net/mvcr`
       */
      if (nextFragment.startsWith("http") && newUrl.length === 0) {
        return nextFragment;
      } else if (!url.endsWith("/") && !nextFragment?.startsWith("/")) {
        newUrl = `${newUrl}/${nextFragment}`;
      } else if (
        (!url.endsWith("/") && nextFragment?.startsWith("/")) ||
        (url.endsWith("/") && !nextFragment?.startsWith("/"))
      ) {
        newUrl = `${newUrl}${nextFragment}`;
      } else if (url.endsWith("/") && nextFragment?.startsWith("/")) {
        newUrl = `${newUrl}${nextFragment.substring(1)}`;
      }
    }
    return newUrl;
  }, "");
}

export const buildRequestUrl = (path: string) => {
  // The request url is constructed from the base url in the browser
  const baseUrl = getBaseUrl();

  // Env vars are not available at this point so cannot use them to build url,
  // therefore the request path is hardcoded here and combined with the
  // base url to construct the appropriate API route
  return combineUrlFragments(
    baseUrl,
    `/${process.env.API_URL}/${stripLeadingSlash(path)}`
  );
}

/**
 * Strips trailing slashes from string
 * @param str - String to strip slash from
 * @returns String with trailing slash stripped
 */
export const stripTrailingSlash = (str: string) => {
  let slicedString: string = str;

  while (slicedString?.length > 0 && slicedString?.endsWith("/")) {
    slicedString = slicedString.slice(0, -1);
  }

  return slicedString;
};

export const stripLeadingSlash = (str: string) => {
  let slicedString: string = str;

  while (slicedString?.length > 0 && slicedString?.startsWith("/")) {
    slicedString = slicedString.slice(1);
  }

  return slicedString;
}

import GemsisActivityMonitor from "./vendor/gemsis/activity-monitor";
import { GEMSIS_TTL_KEY } from "./vendor/gemsis/constants";
import Routes from "./routes";
import { useCookies } from "react-cookie";
import "./App.scss";
import { AuthWall } from "./context/user-context";
import { MVCR1_USER } from "./components/keycloak-context/constants";
import { v4 as uuid } from "uuid";
import { lazy, Suspense, useEffect } from "react";
import { SecurityLevel } from "./components/security-banner";
import { BandwidthIndicatorService } from "./services";
import { useUISettingsSelector } from "state/slices/ui-settings";
import { Ion } from "cesium";
import { setAgGridLicense } from "services/agGridService";

const SecurityBanner = lazy(
  () =>
    import(
      /* webpackChunkName: "SecurityBanner" */ "./components/security-banner"
    )
);
declare global {
  interface Window {
    init_login: boolean;
    SECURITY_LEVEL: SecurityLevel;
  }
}
window.init_login = false;

const App = () => {
  const [cookies, setCookie] = useCookies([GEMSIS_TTL_KEY, "mvcr_session"]);
  const acknowledged = Number(cookies[GEMSIS_TTL_KEY]) === 1;

  // Pull app config from redux store
  const appConfig = useUISettingsSelector((state) => state.appConfig);

  const securityLevel =
    (window.SECURITY_LEVEL as SecurityLevel) ??
    (appConfig?.SECURITY_LEVEL as SecurityLevel) ??
    SecurityLevel.UNCLASSIFIED;

  useEffect(() => {
    if (acknowledged) {
      let sessionId = cookies["mvcr_session"];
      if (!sessionId) {
        sessionId = uuid();
        setCookie("mvcr_session", sessionId, { path: "/" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acknowledged]);

  useEffect(() => {
    if (appConfig) {
      Ion.defaultAccessToken = appConfig.REACT_APP_ION_TOKEN;
      // Set AG Grid license
      setAgGridLicense(appConfig.AG_GRID_LICENSE);

      const propertiesMap = {
        API_URL: process.env.API_URL,
        ACCOUNT_SVC_BASE_URL: process.env.ACCOUNT_SVC_BASE_URL,
        DATA_MEDIATION_SVC_BASE_URL: process.env.DATA_MEDIATION_SVC_BASE_URL,
        DATA_CFG_SVC_BASE_URL: process.env.DATA_CFG_SVC_BASE_URL,
        EXERCISE_SVC_BASE_URL: process.env.EXERCISE_SVC_BASE_URL,
        PROPAGATION_SVC_BASE_URL: process.env.PROPAGATION_SVC_BASE_URL,
        SPECTRUM_INFO_SVC_BASE_URL: process.env.SPECTRUM_INFO_SVC_BASE_URL,
        WORKSPACE_SVC_BASE_URL: process.env.WORKSPACE_SVC_BASE_URL,
        NOTIFICATIONS_SVC_BASE_URL: process.env.NOTIFICATIONS_SVC_BASE_URL,

        REACT_APP_MSG_BUS_PATH: process.env.REACT_APP_MSG_BUS_PATH,
        REACT_APP_SOCKET_GATEWAY: process.env.REACT_APP_SOCKET_GATEWAY,

        PUBLIC_URL: process.env.PUBLIC_URL,
        REACT_ROUTER_BASENAME: process.env.REACT_ROUTER_BASENAME,

        SECURITY_LEVEL: appConfig?.SECURITY_LEVEL,
        VERSION: process.env.VERSION,
        GIT_BRANCH: process.env.GIT_BRANCH,
        GIT_COMMITHASH: process.env.GIT_COMMITHASH
      };

      console.log("***** ENVIRONMENT *****");
      Object.keys(propertiesMap).forEach((propKey) => {
        console.log(`${propKey}: ${propertiesMap[propKey]}`);
      });
      console.log("***** ENVIRONMENT *****");
      console.log("***** GIT HISTORY *****");
      console.log(process.env.GIT_HISTORY);
      console.log("***** GIT HISTORY *****");
    }
  }, [appConfig]);

  useEffect(() => {
    const timeoutId = setInterval(
      () => BandwidthIndicatorService.getBandwidth(),
      60000
    );
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <AuthWall roles={[MVCR1_USER]} acknowledged={acknowledged}>
      <Suspense fallback={<div />}>
        <SecurityBanner securityLevel={securityLevel} />
      </Suspense>
      <GemsisActivityMonitor />
      <Routes />
    </AuthWall>
  );
};

export default App;

import { GEMSIS_TTL_EXPIRE_KEY, GEMSIS_TTL_KEY } from "vendor/gemsis/constants";
import { keycloak } from "keycloak";
import { combineUrlFragments } from "util/url-builder";

const REACT_ROUTER_BASENAME = process.env.REACT_ROUTER_BASENAME || "";

/**
 * Service providing methods for logging the user out of the application
 */
export const logoutService = {
  /**
   *Check if a cookie exists
   * @param {string} cookieToCheck - string of cookie name to check
   * @returns {boolean} boolean indicating if `cookieToCheck` exists in document.cookie
   */
  cookieExists: (cookieToCheck: string) => {
    const cookiesArray = document.cookie.split(";").map((c) => c.split("=")[0]);
    return cookiesArray.indexOf(cookieToCheck) !== -1;
  },
  /**
   * Remove the specified cookies
   * @param {string[]} cookiesToRemove - string[] of cookie names to be removed
   * @returns {boolean} boolean indicating if all `cookiesToRemove` were successfully deleted
   */
  removeCookies: (cookiesToRemove: string[]) => {
    const successfulRemovals = [];
    cookiesToRemove.forEach((cookieToRemove) => {
      document.cookie = `${cookieToRemove}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      const cookiesArray = document.cookie
        .split(";")
        .map((c) => c.split("=")[0]);
      if (cookiesArray.indexOf(cookieToRemove) === -1) {
        successfulRemovals.push(true);
      } else {
        successfulRemovals.push(false);
      }
    });
    return successfulRemovals.every((val) => val === true);
  },
  /**
   * Perform the standard logout functionality for MVCR1 by deleting the
   * appropriate cookies and calling `logout()` on the passed in parm
   */
  doLogout: () => {
    const cookieRemovalSuccess = logoutService.removeCookies([
      GEMSIS_TTL_KEY,
      GEMSIS_TTL_EXPIRE_KEY,
      "mvcr_session"
    ]);

    // c2s error reported: `Invalid parameter: redirect_uri`
    // Add log of redirectUri value here for additional debugging
    console.log(
      `redirectUri: ${window.location.origin}${combineUrlFragments(
        REACT_ROUTER_BASENAME,
        `/logout/index.html?lastPage=${window.location.href}`
      )}`
    );

    if (cookieRemovalSuccess) {
      keycloak?.logout({
        redirectUri: `${window.location.origin}${combineUrlFragments(
          REACT_ROUTER_BASENAME,
          `/logout/index.html?lastPage=${window.location.href}`
        )}`
      });
    } else {
      console.log("error removing cookies");
    }
  }
};

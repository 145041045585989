import { AxiosResponse } from "axios";

export default function HandleHttpResponse<T>(
  response: AxiosResponse<T>,
  errorMessage?: string
): T {
  if (response.status === 200) {
    return response.data;
  } else {
    throw errorMessage;
  }
}

import { Fragment, useEffect, useState } from "react";
import LogOutModal from "../../components/logout-modal/logout-modal";
import { useCookies } from "react-cookie";
import _ from "lodash";
import {
  GEMSIS_TTL_KEY,
  GEMSIS_TTL_EXPIRE_KEY,
  GEMSIS_INACTIVITY_TIMEOUT_SECONDS,
  GEMSIS_COOKIE_TIMEOUT_SECONDS,
  GEMSIS_TTL_VALUE,
  GEMSIS_INACTIVITY_ALERT_SECONDS
} from "./constants";
import useInterval from "../../services/useInterval";
import { logoutService } from "services/logoutService";

export const gemsisEvents = {
  moveDebounced: () => {},
  removeCookies: () => {},
  statePopDebounced: () => {}
};

const GemsisActivityMonitor: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [secondsUntilTimeout, setSecondsUntilTimeout] = useState(
    GEMSIS_INACTIVITY_TIMEOUT_SECONDS
  );
  const [intervalPeriod, setIntervalPeriod] = useState<number | null>(1000);
  const [delay, setDelay] = useState<number | null>(10000);
  const [cookies, setCookie, removeCookie] = useCookies([
    GEMSIS_TTL_KEY,
    GEMSIS_TTL_EXPIRE_KEY
  ]);

  useEffect(() => {
    // set up debounced functions to attach to activity listeners
    gemsisEvents.moveDebounced = _.debounce(renewTimeout, 500, {
      leading: true,
      maxWait: 1000
    });
    gemsisEvents.statePopDebounced = _.debounce(renewTimeout, 500);
    gemsisEvents.removeCookies = () => {
      removeCookie(GEMSIS_TTL_KEY);
      removeCookie(GEMSIS_TTL_EXPIRE_KEY);
    };

    // renew timeout on document body click
    document.body.addEventListener("click", gemsisEvents.moveDebounced, false);
    // renew timeout on mouse movement
    document.body.addEventListener("mousemove", gemsisEvents.moveDebounced);
    // renew timeout on URL change
    window.addEventListener("popstate", gemsisEvents.statePopDebounced);

    // clean up during unmount
    return () => {
      document.body.removeEventListener("click", gemsisEvents.moveDebounced);
      document.body.removeEventListener(
        "mousemove",
        gemsisEvents.moveDebounced
      );
      window.removeEventListener("popstate", gemsisEvents.statePopDebounced);
      gemsisEvents.removeCookies();
      setIntervalPeriod(null);
      setDelay(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(
    () => {
      const cookieTTL = cookies[GEMSIS_TTL_KEY];

      if (cookieTTL !== GEMSIS_TTL_VALUE) {
        setShowModal(false);
      } else {
        const cookieExpire = cookies[GEMSIS_TTL_EXPIRE_KEY];
        const secondsRemaining = getSecondsRemaining(
          cookieExpire,
          new Date().toLocaleString()
        );

        if (secondsRemaining <= 0) {
          logoutService.doLogout();
        } else if (secondsRemaining <= GEMSIS_INACTIVITY_ALERT_SECONDS) {
          alertPopupFunction(secondsRemaining);
        } else {
          setShowModal(false);
        }
      }
    },
    intervalPeriod,
    delay
  );

  function alertPopupFunction(seconds: number) {
    messageBox(seconds);
  }

  function messageBox(seconds: number) {
    seconds = Math.floor(seconds);
    setSecondsUntilTimeout(seconds);
    setShowModal(true);
  }

  function getSecondsRemaining(d1: string, d2: string): number {
    const t1 = new Date(Date.parse(d1));
    const t2 = new Date(Date.parse(d2));
    const deltaInMilliseconds = t1.getTime() - t2.getTime();

    return deltaInMilliseconds / 1000;
  }

  function hasValidCookie() {
    // make sure cookie exists in browser and in memory
    return (
      decodeURIComponent(document.cookie).includes(GEMSIS_TTL_KEY) &&
      Number(cookies[GEMSIS_TTL_KEY]) === 1
    );
  }

  function renewTimeout() {
    if (hasValidCookie()) {
      setShowModal(false);

      // inactivity expiration (defined by GEMSIS_INACTIVITY_TIMEOUT)
      const inactivityExpiration = new Date();
      inactivityExpiration.setSeconds(
        inactivityExpiration.getSeconds() + GEMSIS_INACTIVITY_TIMEOUT_SECONDS
      );

      // cookie expiration (24 hours)
      const cookieExpiration = new Date();
      cookieExpiration.setTime(
        cookieExpiration.getTime() + GEMSIS_COOKIE_TIMEOUT_SECONDS * 1000
      );

      // renew cookies
      setCookie(GEMSIS_TTL_KEY, GEMSIS_TTL_VALUE, {
        path: "/"
      });
      setCookie(GEMSIS_TTL_EXPIRE_KEY, inactivityExpiration.toLocaleString(), {
        path: "/"
      });
    }
  }

  return (
    <Fragment>
      {showModal ? (
        <LogOutModal
          header="Session Expiring"
          body="Move your mouse or close this window to prevent log out."
          footer=""
          onClose={() => renewTimeout()}
          secondsRemaining={secondsUntilTimeout}
        />
      ) : null}
    </Fragment>
  );
};

export default GemsisActivityMonitor;

import axios, { AxiosResponse } from "axios";
import Keycloak, { KeycloakConfig } from "keycloak-js";
import { store } from "state";
import { setKeycloakToken } from "state/slices/keycloak";
import { buildRequestUrl } from 'util/url-builder';

/**
 * Call the callbacks in the queue
 * @returns the keycloak token
 */
export const refreshToken = (minValidity = 5) => {
  if (!keycloak) return;

  return keycloak.updateToken(minValidity).then(() => {
    store.dispatch(setKeycloakToken(keycloak.token));

    return keycloak.token;
  });
};

// Normally we'd run this thru rtk, but our queries are setup to use the bearer token we
// will ultimately get after successfully retrieving this data.  Possible future fix would
// be to create an rtk query that doesn't use the bearer token for ONLY this call
const https = axios.create();
export const getKeycloakConfig = async (): Promise<Keycloak | void> => {
  const acctSvcUrl = buildRequestUrl(`/account-svc/keycloak-config`);
  return await https
    .get<KeycloakConfig>(acctSvcUrl)
    .then((resp: AxiosResponse<KeycloakConfig>) => {
      if (Object.keys(resp?.data ?? {})) {
        keycloak = new Keycloak(resp.data);
        return keycloak;
      }
    })
    .catch((loginErr) => {
      console.log(loginErr);
    });
};

export let keycloak: Keycloak = undefined;

import { Viewer } from "cesium";
import { createContext, ReactNode, useContext, useReducer } from "react";

/**
 * define an interface that defines the values of the Context.
 * @property {string} type - optional action type
 * @property {Viewer} viewer - A cesium base widget for building applications
 * @property {Function} dispatch - a function that dispatches actions
 */
export type CesiumViewerContextState = {
  type?: string;
  viewer: Viewer;
  dispatch: Function;
};

/**
 * initializing a default values for the context.
 */
const initialState: CesiumViewerContextState = {
  viewer: undefined,
  dispatch: () => {
    /*no-op*/
  }
};

/**
 * create the Context by using React's createContext function
 */
const CesiumViewerContext =
  createContext<CesiumViewerContextState>(initialState);

const cesiumReducer = (state: any, action: CesiumViewerContextState) => {
  if (action.type === "CESIUM_VIEWER") {
    return {
      ...state,
      viewer: action.viewer
    };
  }
};

/**
 * Create a provider component
 * @param children provider's children component.
 * @returns provider that wrap around the code that will use the Context.
 */
const CesiumViewerProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [state, dispatch] = useReducer(cesiumReducer, initialState);
  // The value prop allows us to set values that are different from the defaults.
  const value = { ...state, dispatch };
  return (
    <CesiumViewerContext.Provider value={value}>
      {children}
    </CesiumViewerContext.Provider>
  );
};

const useCesiumViewerContext = () => useContext(CesiumViewerContext);
export { CesiumViewerContext, useCesiumViewerContext, CesiumViewerProvider };

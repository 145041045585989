import React from "react";
import "./index.styles.scss";

export interface ErrorBoundaryStateModel {
  error: any;
  hasError: boolean;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  state: ErrorBoundaryStateModel = {
    error: null,
    hasError: false
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error);
    console.error(errorInfo);
    this.state.error = error;
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="_errorBoundary">
          <h1>Some application resources could not be loaded.</h1>
          <h1>
            This is usually a result of a connection issue or firewall setting.
            Please contact your network administrator.
            {this.state.error ? this.state.error : ""}
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import { BaseApplicationConfigModel } from "../@types/data-config.model";
import { store } from "state";

/**
 * Helper function to assist with pulling the redux stored
 * app configs outside of React.FC components where the
 * useUISettingsSelector cannot be used.
 */
const pullAppConfigFromRedux = () => {
  return store.getState().uiSettings?.appConfig;
};

/**
 * Constructs and adds the additional commonly used env vars
 * that are derived from the base app config env vars.
 *
 * As the application grows both app config models will likely evolve
 * as new primary, base env vars are added to `BaseApplicationConfigModel`
 * and new env vars are derived from the base set and added to
 * `ApplicationConfigModel`
 */
export const buildAppConfig = (config?: BaseApplicationConfigModel) => {
  return config ?? pullAppConfigFromRedux();
};

export const appConfig = buildAppConfig();

import handleHttpError from "./handleHttpError";
import handleHttpResponse from "./handleHttpResponse";
import HttpsService from "./httpsService";

const DataMediationService = {
  getAllSatellites: () => {
    let https = HttpsService.getHttps();
    return https
      ?.request({
        method: "get",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        url: `${process.env.DATA_MEDIATION_SVC_BASE_URL}${process.env.API_URL}/data-mediation-svc/udl`
      })
      .then((res: any) =>
        handleHttpResponse(res, "Failed to get all for common data layer")
      )
      .catch((err: any) => handleHttpError(err, []));
  }
};

export default DataMediationService;

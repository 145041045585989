import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUseSliceSelector } from "../util";
import { KeycloakProfile, KeycloakTokenParsed } from "keycloak-js";

/**
 * Duplicates KeyCloak state, making it accessible throughout the application
 */
export interface KeycloakAccountState {
  token: string | undefined;
  user: KeycloakTokenParsed | undefined;
  profile: KeycloakProfile | undefined;
}

/**
 * sets the state of the slice to be an empty array.
 */
const initialState: KeycloakAccountState = {
  token: undefined,
  user: undefined,
  profile: undefined,
};

type KeycloakStatePayload = KeycloakAccountState;

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const keycloakSlice = createSlice({
  name: "keycloak",
  initialState,
  reducers: {
    /**
     * Convenience reducer to overwrite user, profile and token at the same time
     */
    setKeycloakState(state, action: PayloadAction<KeycloakStatePayload>) {
      const { user, profile, token } = action.payload;
      state.user = user;
      state.profile = profile;
      state.token = token;
    },
    /**
     * Overwrite current keycloak token
     */
    setKeycloakToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    /**
     * Overwrite current keycloak token
     */
    setKeycloakProfile(state, action: PayloadAction<KeycloakProfile>) {
      state.profile = action.payload;
    },
    /**
     * Overwrite current keycloak token
     */
    setKeycloakUser(state, action: PayloadAction<KeycloakTokenParsed>) {
      state.user = action.payload;
    },
    /**
     * Reset keycloak token back to undefined
     */
    clearKeycloakToken(state) {
      state.token = undefined;
    },
  }
});

export const {
  setKeycloakState,
  setKeycloakToken,
  setKeycloakProfile,
  setKeycloakUser,
  clearKeycloakToken,
} = keycloakSlice.actions;

export const useKeycloakSelector =
  createUseSliceSelector(keycloakSlice);

export default keycloakSlice;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";
import { createUseSliceSelector } from "state/util";
import UISettings from "../../@types/uiSettings.model";
import ImageryProviderSetting from "../../@types/imageryProvider.model";
import TerrainProviderSetting from "../../@types/terrainProvider.model";
import { BaseApplicationConfigModel } from "../../@types/data-config.model";

type Server = {
  host: string;
  port: number;
};

export interface UISettingsState {
  /**
   * Stored UI settings.
   */
  uiSettings: UISettings | null;
  /**
   * Stored Imagery providers
   */
  imageryProviders: ImageryProviderSetting[] | null;
  /**
   * Stored Terrain providers.
   */
  terrainProviders: TerrainProviderSetting[] | null;
  /**
   * Trusted servers to apply in Cesium.
   */
  trustedServers: Server[] | null;
  /**
   * Stored UI environment variables
   */
  appConfig: BaseApplicationConfigModel | null;
}

export interface ImageryProvidersPayload {
  imageryProviders: ImageryProviderSetting[];
}

export interface TerrainProvidersPayload {
  terrainProviders: TerrainProviderSetting[];
}

export interface TrustedServersPayload {
  trustedServers: Server[];
}

export interface UiSettingsPayload {
  uiSettings: UISettings;
}

export interface AppConfigPayload {
  appConfig: BaseApplicationConfigModel;
}

const initialState: UISettingsState = {
  imageryProviders: null,
  terrainProviders: null,
  trustedServers: null,
  uiSettings: null,
  appConfig: null
};

export const uiSettingsSlice = createSlice({
  name: "uiSettings",
  initialState,
  reducers: {
    /**
     * Overwrite the imagery provider value or create one in state
     * if none exists.
     */
    setImageryProviders(state, action: PayloadAction<ImageryProvidersPayload>) {
      set(state, "imageryProviders", action.payload.imageryProviders);
    },
    /**
     * Overwrite the terrain provider value or create one in state
     * if none exists.
     */
    setTerrainProviders(state, action: PayloadAction<TerrainProvidersPayload>) {
      set(state, "terrainProviders", action.payload.terrainProviders);
    },
    /**
     * Overwrite the trusted servers value or create one in state
     * if none exists.
     */
    setTrustedServers(state, action: PayloadAction<TrustedServersPayload>) {
      state.trustedServers = action.payload.trustedServers ?? [];
    },
    /**
     * Overwrite the uiSettings value or create one in state
     * if none exists.
     */
    setUiSettings(state, action: PayloadAction<UiSettingsPayload>) {
      set(state, "uiSettings", action.payload.uiSettings);
    },

    /**
     * Adds items to the imagery providers value
     */
    addImageryProviders(state, action: PayloadAction<ImageryProvidersPayload>) {
      state.imageryProviders.push(...action?.payload?.imageryProviders);
    },
    /**
     * Adds items to the terrain providers value
     */
    addTerrainProviders(state, action: PayloadAction<TerrainProvidersPayload>) {
      state.terrainProviders.push(...action?.payload?.terrainProviders);
    },
    /**
     * Overwrite the app config value or create one in state
     * if none exists.
     */
    setAppConfig(state, action: PayloadAction<AppConfigPayload>) {
      set(state, "appConfig", action.payload.appConfig);
    }
  }
});

export const {
  setImageryProviders,
  addImageryProviders,
  setTerrainProviders,
  addTerrainProviders,
  setTrustedServers,
  setUiSettings,
  setAppConfig
} = uiSettingsSlice.actions;

export const useUISettingsSelector = createUseSliceSelector(uiSettingsSlice);

export default uiSettingsSlice;

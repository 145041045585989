import { cloneDeep } from "lodash";
import {
  AvailableTourTypes,
  RegisteredTourSteps,
  TourTypes
} from "../../@types/tour.model";
import { FC, ReactNode, createContext, useState } from "react";

/**
 * Interface definition for TourTracker data
 * @property {string} type - Action type (optional)
 * @property {RegisteredTourSteps} registeredTourSteps - List of component classNames or ids associated with specified tour's steps
 * @property {Function} addRegisteredTourStep - Add provided step to registered tour list
 */
interface TourTrackerContextState {
  registeredTourSteps: RegisteredTourSteps;
  addRegisteredTourStep: (tour: TourTypes, step: string) => void;
}

/**
 * Base structure for RegisteredTourSteps object
 */
const baseRegisteredTourSteps: RegisteredTourSteps = Object.values(
  AvailableTourTypes
).reduce((acc, value) => {
  acc[value] = new Set<string>();
  return acc;
}, {} as RegisteredTourSteps);

// Create initial context state
export const TourTrackerContext = createContext<TourTrackerContextState>({
  registeredTourSteps: cloneDeep(baseRegisteredTourSteps),
  addRegisteredTourStep: () => {}
});

/**
 * Create the context provider for tracking registered components related to tour steps
 * @param children provider's children component.
 * @returns provider that wrap around the code that will use the Context.
 */
export const TourStepTracker: FC<{ children: ReactNode }> = ({
  children
}): JSX.Element => {
  const [registeredTourSteps, setRegisteredTourSteps] =
    useState<RegisteredTourSteps>(cloneDeep(baseRegisteredTourSteps));

  // Add tour step to tracked list if the registered steps for a tour does not already include the step
  const addRegisteredTourStep = (tour: TourTypes, step: string) => {
    setRegisteredTourSteps((prevTourSteps) => ({
      ...prevTourSteps,
      [tour]: new Set(prevTourSteps[tour]).add(step)
    }));
  };

  return (
    <TourTrackerContext.Provider
      value={{
        registeredTourSteps,
        addRegisteredTourStep
      }}
    >
      {children}
    </TourTrackerContext.Provider>
  );
};

import { setBandwidth } from "state/slices/bandwidth";
import { store } from "state";

const BandwidthIndicatorService = {
  getBandwidth: () => {
    const showResults = () => {
      const duration = Math.max((endTime - startTime) / 1000 - latency, 0);
      const bitsLoaded = bandwidthDownloadSize * 8;
      const speedBps = bitsLoaded / duration;
      const speedKbps = speedBps / 1024;
      const speedMbps = speedKbps / 1024;
      console.log(`Your estimated bandwidth is: ${speedMbps > 5 ? ">5" : speedMbps} Mbps\n`);
      store.dispatch(setBandwidth({ bandwidth: speedMbps }));
    };
    
    const randomNumberArray = crypto.getRandomValues(new Uint16Array(4));
    const randomNumber = randomNumberArray.join('');
    let startTime: number;
    let endTime: number;
    let latency: number;

    const latencyDownload = new Image();
    latencyDownload.onload = async function () {
      endTime = new Date().getTime();
      latency = (endTime - startTime) / 1000;
      startTime = new Date().getTime();
      bandwidthDownload.src = `${process.env.PUBLIC_URL || ""}/bandwidth-test/logo_10kb.png?n=${randomNumber}`;
    }
    latencyDownload.onerror = async function () {
      if (navigator.onLine === false) {
        console.log("Your network is offline");
        store.dispatch(setBandwidth({ bandwidth: null }));
      }
    };
    startTime = new Date().getTime();
    latencyDownload.src = `${process.env.PUBLIC_URL || ""}/bandwidth-test/logo_minimal.png?n=${randomNumber}`;

    const bandwidthDownloadSize = 11000;
    const bandwidthDownload = new Image();
    bandwidthDownload.onload = async function () {
      endTime = new Date().getTime();
      showResults();
    };
    bandwidthDownload.onerror = async function () {
      if (navigator.onLine === false) {
        console.log("Your network is offline");
        store.dispatch(setBandwidth({ bandwidth: null }));
      }
    };
  }
};

export default BandwidthIndicatorService;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createUseSliceSelector, createUseValueSelector } from "../util";

interface BandwidthState {
  /**
   * A number specifying the Mbps bandwidth connection status of the user.
   */
  bandwidth: number;
  isMeasuring: boolean;
}

const initialState: BandwidthState = {
  bandwidth: null,
  isMeasuring: true,
};

interface BandwidthPayload {
  bandwidth: number;
}

export const bandwidthSlice = createSlice({
  name: "bandwidth",
  initialState,
  reducers: {
    setBandwidth(state, action: PayloadAction<BandwidthPayload>) {
      const { bandwidth } = action.payload;
      state.bandwidth = bandwidth;
      state.isMeasuring = false;
    },
  }
});

export const { setBandwidth } = bandwidthSlice.actions;

export const useBandwidthSliceSelector = createUseSliceSelector(bandwidthSlice);

/**
 * This selector pulls the last bandwidth measurement value for the user, in Mbps, from the state store.
 */
export const useBandwidthSelector = createUseValueSelector(bandwidthSlice, "bandwidth");
export const useBandwidthIsMeasuringSelector = createUseValueSelector(bandwidthSlice, "isMeasuring");

export default bandwidthSlice;

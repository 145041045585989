import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FC, lazy, Suspense } from "react";
import { stripTrailingSlash } from "util/url-builder";

const BasePageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "BasePageComponent" */ "components/base-page-component"
    )
);
const NotFoundPage = lazy(
  () => import(/* webpackChunkName: "NotFoundPage" */ "../pages/not-found")
);
const WorkspacePage = lazy(
  () => import(/* webpackChunkName: "WorkspacePage" */ "../pages/workspace")
);
const GoatReportsPage = lazy(
  () =>
    import(/* webpackChunkName: "GoatReportsPage" */ "../pages/goat-reports")
);

/**
 * @prop {boolean} hideNavBar Checks whether the top navigation bar should be hidden
 * @prop {boolean} displayFullHeight Checks whether the page should expand the full height of the screen
 */
type LazyBasePageComponentProps = {
  hideNavBar?: boolean;
  displayFullHeight?: boolean;
};

export default function AppRoutes() {
  const REACT_ROUTER_BASENAME =
    stripTrailingSlash(process.env.REACT_ROUTER_BASENAME) || "/";
  /*
    ** The following `useEffect` was initially commented out by Dylan Culfogienis. The `cookies` was then considered unused and
          causing Code Smells. I'm leaving the below comments for potential future use.

    const [cookies] = useCookies(["mvcr_session"]);

    useEffect(() => {
      async function connectSocket() {
        await SocketIoService.connect(keycloak.token);
        SocketIoService.emit("join", cookies["mvcr_session"]);
      }
      connectSocket();
    }, [keycloak, cookies]);
   */

  const LazyBasePageComponent: FC<LazyBasePageComponentProps> = ({
    hideNavBar = false,
    displayFullHeight = false,
    children
  }) => (
    <Suspense fallback={<div />}>
      <BasePageComponent {...{ hideNavBar, displayFullHeight }}>
        {children}
      </BasePageComponent>
    </Suspense>
  );

  return (
    <BrowserRouter basename={REACT_ROUTER_BASENAME}>
      <Routes>
        <Route
          path="/workspace/:workspaceId"
          element={
            <LazyBasePageComponent>
              <WorkspacePage />
            </LazyBasePageComponent>
          }
        />
        <Route
          path="/workspace"
          element={
            <LazyBasePageComponent>
              <WorkspacePage />
            </LazyBasePageComponent>
          }
        />
        <Route
          path="/reports/goat-reports"
          element={
            <LazyBasePageComponent hideNavBar={true} displayFullHeight={true}>
              <GoatReportsPage />
            </LazyBasePageComponent>
          }
        />
        <Route
          index
          path="/"
          element={
            <LazyBasePageComponent>
              <WorkspacePage />
            </LazyBasePageComponent>
          }
        />
        <Route
          path="*"
          element={
            <LazyBasePageComponent>
              <NotFoundPage />
            </LazyBasePageComponent>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PARequestType } from "components/propagation/model";
import { set } from "lodash";
import { createUseSliceSelector } from "../util";

/**
 * @param {string} userSettingsId current user's ID.
 * @param {string[]} hideAnalysesById a list of untoggled/hidden propogations ID.
 * @param {PARequestType[]} hideAnalysesByType a list of untoggled/hidden propogations type ex. hideAnalysesByType = [proploss, ppa, multiLinkBudget, radarRange].
 */
export interface IuserSettingAnalyses {
  userSettingsId: string;
  hideAnalysesById: string[];
  hideAnalysesByType: PARequestType[];
}
/**
 * Stored userSettingAnalyses that the user is editing.
 * These values are intended to override the currently cached
 * userSettingAnalyses values.
 */
export interface UserSettingsState {
  userSettingAnalyses: IuserSettingAnalyses;
}

/**
 * sets the state of the slice to be empty.
 */
const initialState: UserSettingsState = {
  userSettingAnalyses: {
    userSettingsId: "",
    hideAnalysesById: [],
    hideAnalysesByType: []
  }
};

/**
 * allows the userSettingAnalyses to be called using just the name
 */
export interface UserSettingsStatePayload {
  userSettingAnalyses: IuserSettingAnalyses;
}

/**
 * allows to make partial updated
 */
export interface PartialUserSettingsStatePayload {
  userSettingAnalyses: Partial<IuserSettingAnalyses>;
}

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const userSettingsStateSlice = createSlice({
  name: "userSettingAnalyses",
  initialState,
  reducers: {
    /**
     * Overwrite the current userSettingAnalyses value, or create one in state if it doesn't exist.
     * @param state is the current state of the slice
     * @param action is the action to take place (create, update, delete)
     */
    setUserSettingAnalyses(
      state,
      action: PayloadAction<UserSettingsStatePayload>
    ) {
      if (action?.payload?.userSettingAnalyses)
        state.userSettingAnalyses = action.payload.userSettingAnalyses;
    },
    /**
     * Updated userSettingAnalyses values. A value will only be changed
     * if it is defined in the payload.
     */
    updateUserSettingAnalyses(
      state,
      action: PayloadAction<PartialUserSettingsStatePayload>
    ) {
      const tempSettings = {
        ...(state.userSettingAnalyses ?? {}),
        ...action.payload.userSettingAnalyses
      };
      set(state, "userSettingAnalyses", tempSettings);
    }
  }
});

export const { setUserSettingAnalyses, updateUserSettingAnalyses } = userSettingsStateSlice.actions;

export const useUserSettingAnalysesSelector = createUseSliceSelector(
  userSettingsStateSlice
);

export default userSettingsStateSlice;

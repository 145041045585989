import axios, { AxiosInstance } from "axios";
import _ from "lodash";
import { keycloak, refreshToken } from "../keycloak";
import ENV from "./envService";

const HttpsConnections = {};

export const lastCsrfToken = null;

const PUBLIC_URL = ENV.PUBLIC_URL;
if (PUBLIC_URL) axios.defaults.baseURL = PUBLIC_URL;

const HttpsService = {
  getHttps() {
    let httpsInstance: AxiosInstance = _.get(HttpsConnections, "mvcr");
    if (_.isUndefined(httpsInstance)) {
      httpsInstance = axios.create({});

      httpsInstance.interceptors.request.use((config) => {
        let params = _.get(config, "params", {});
        if (typeof params["_ts"] === "undefined") {
          params["_ts"] = String(new Date().getTime());
          config.params = params;
        }
        return refreshToken()
          .then(() => {
            config.headers.Authorization = "Bearer " + keycloak.token;
            return config;
          })
          .catch((err) => {
            console.error(err);
            keycloak.login();
            return config;
          });
      });
      _.set(HttpsConnections, "mvcr", httpsInstance);
    }

    return httpsInstance;
  }
};

export default HttpsService;

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUseSliceSelector } from "state/util";
import { DataSourceTitle } from "../../@types/filter.model";
import { AreaOfInterestModel } from "../../@types/AreaOfInterest.model";
import { RootState } from "state";
import CDLQueryRequestParams from "services/@types/commonDataLayerServiceAPI.model";
import { CDLDataKey } from "../../@types/common-data-layer.model";
import { WorkspaceFilter } from "@mvcr/mvcr-common";
import { isEmpty } from "lodash";

/**
 * Updates to this type may affect much of the logic below.
 */
export type JobStatus =
  // Statuses considered an 'open job'
  | "SUBMITTED"
  | "ACKNOWLEDGED"
  | "IN-PROGRESS"

  // Done or incomplete statuses
  | "DONE"
  | "DATA-LIMIT"
  | "ERROR"
  | "INCOMPLETE"
  | "STOPPED";

export type JobHash = string;

/**
 *
 */
export type DataJob = {
  label: string; // human readable name
  jobHash: JobHash;
  status: JobStatus;
  message?: string;
  totalHits?: number;
  pageSize: number;
  recordsReceived: number;
  params: CDLQueryRequestParams;
  area: AreaOfInterestModel;
  dataSource: DataSourceTitle;
  filters: WorkspaceFilter[];
  pitId: string;
  lastSort?: Record<string, "asc" | "desc">[];
  entityThreshold: number;
  created: number;
  pageNumber: number;
  supplemental?: boolean;
  supplementalParams?: SupplDataJob;
};

export type DataJobs = {
  [key: JobHash]: DataJob;
};

/**
 * These are the params that could be updated
 */
export type UpdateDataJobType = {
  jobHash: JobHash;
  status?: JobStatus;
  totalHits?: number;
  totalFilteredHits?: number;
  recordsReceived?: number;
  pitId?: string;
  lastSort?: Record<string, "asc" | "desc">[];
  pageNumber?: number;
  message?: string;
};
/**
 * Max number of records contained per packet
 */
export const jobPacketPageSize = 1000;
export const tempColumnStatusCache = new Set<CDLDataKey>();

export type DataKeyStatusMap = Record<CDLDataKey, JobStatus>;
export type RequestingModuleID = string;
export type SupplDataStatusMap = Record<
  RequestingModuleID,
  Partial<DataKeyStatusMap>
>;
export type RequestingModuleIDInterface = {
  moduleId: RequestingModuleID;
};
/**
 * @prop {string} moduleId Identifier from table window
 * @prop {CDLDataKey[]} keys Active columns from table
 * @prop {JobStatus} status (Optional) Indicates the column status (defaults to 'SUBMITTED')
 */
export type UpdateSupplKeyStatusPayload = {
  moduleId: string;
  keys: CDLDataKey[];
  status?: JobStatus;
};

export type SupplDataJob = {
  moduleId: RequestingModuleID;
  keysStatus: Partial<DataKeyStatusMap>;
};

// Prefix added to 'filter' supplemental statuses
export const SupplFilterJobPrefix = "filter-module_";
// Prefix added to 'table' supplemental statuses
export const TabularDataJobPrefix = "tabular-data-window_";
// Prefix added to 'analysis' supplemental statuses
export const AnalysisRequestJobPrefix = "analysis_job_";

/**
 * socketId - client socketId generated upon connection with the socket server in message-bus svc
 * dataJobs - record of hashed jobs and their status generated when requesting jobs. Jobs are initialized
 * with the "IN-PROGRESS" status.
 * Implementation can be found in /services/CommonDataLayerService.ts
 */
export interface DataVolumeState {
  /** Tracks if socket is connected and dataNamespace room is joined */
  isRoomReady: boolean;

  socketId: string;
  dataJobs: DataJobs;
  supplDataStatus?: SupplDataStatusMap;
}

const initialState: DataVolumeState = {
  isRoomReady: false,
  socketId: undefined,
  dataJobs: {},
  supplDataStatus: {}
};

export const dataVolumeSlice = createSlice({
  name: "dataVolume",
  initialState,
  reducers: {
    addDataJob(state, action: PayloadAction<DataJob>) {
      state.dataJobs[action.payload.jobHash] = action.payload;
    },
    updateDataJob(state, action: PayloadAction<UpdateDataJobType>) {
      state.dataJobs[action.payload.jobHash] = {
        ...state.dataJobs[action.payload.jobHash],
        ...action.payload
      };
    },
    deleteDataJob(state, action: PayloadAction<JobHash>) {
      delete state.dataJobs[action.payload];
    },
    /**
     *  Iterates over payload keys and updates each of those keys in state
     */
    updateSocketId(state, action: PayloadAction<{ socketId: string }>) {
      state.socketId = action.payload.socketId;
    },

    updateIsSocketReady(
      state,
      action: PayloadAction<{ isSocketReady: boolean }>
    ) {
      state.isRoomReady = action.payload.isSocketReady;
    },

    addModuleKeyRequestStatus(state, action: PayloadAction<string>) {
      state.supplDataStatus[action.payload] = {};
    },
    /**
     * Updates the table's columns
     */
    updateModuleKeyRequestStatus(
      state,
      action: PayloadAction<UpdateSupplKeyStatusPayload>
    ) {
      const { moduleId, keys, status } = action.payload;
      if (!state?.supplDataStatus?.[moduleId]) {
        state.supplDataStatus[moduleId] = {};
      }

      // add any NEW columns to state
      keys?.forEach((column) => {
        state.supplDataStatus[moduleId][column] = status ?? "SUBMITTED";
        /**
         * Cache used to stored statuses to prevent additional data requests
         * when a table is opened and closed.
         */
        tempColumnStatusCache.add(column);
      });
    },
    /**
     * function to delete a table option from the store.
     */
    deleteModuleKeyRequestStatus(
      state,
      action: PayloadAction<RequestingModuleIDInterface>
    ) {
      const { moduleId } = action.payload;
      delete state.supplDataStatus[moduleId];
    },
    /** Remove any jobs that are flagged as a DATA-LIMIT */
    removeAllDataLimitJobs(state) {
      if (state.dataJobs) {
        Object.keys(state.dataJobs).forEach((job) => {
          if (state.dataJobs[job].status === "DATA-LIMIT") {
            delete state.dataJobs[job];
          }
        });
      }
    }
  }
});

export const {
  updateSocketId,
  removeAllDataLimitJobs,
  addDataJob,
  updateDataJob,
  deleteDataJob,
  addModuleKeyRequestStatus,
  updateModuleKeyRequestStatus,
  deleteModuleKeyRequestStatus,
  updateIsSocketReady
} = dataVolumeSlice.actions;

export const useDataVolumeSelector = createUseSliceSelector(dataVolumeSlice);

export const useDataVolumeJobsSelector = (state: RootState) =>
  state.dataVolume.dataJobs;

export const useSupplDataFieldsSelector = (state: RootState) =>
  state.dataVolume.supplDataStatus;

/**
 * Since the selector performs a `filter` on the dataJobs,
 * it means we'll always return a new array instance and
 * thus trigger our listeners. As a simple optimization,
 * we'll be sure to always return this empty array instance
 * if the filter did not find any results
 */
const emptyInProgressSupplJobsArray: DataJob[] = [];

/**
 * Provides the current list of IN-PROGRESS/SUBMITTED jobs for supplemental requests.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const inProgressSupplDataJobsSelector = createSelector(
  useDataVolumeJobsSelector,
  (dataJobs) => {
    const filteredDataJobs = Object.values(dataJobs).filter(
      (job) =>
        job.supplemental &&
        (job.status === "IN-PROGRESS" ||
          job.status === "SUBMITTED" ||
          job.status === "ACKNOWLEDGED")
    );
    if (isEmpty(filteredDataJobs)) return emptyInProgressSupplJobsArray;
    else return filteredDataJobs;
  }
);

/**
 * Since the selector performs a `filter` on the dataJobs,
 * it means we'll always return a new array instance and
 * thus trigger our listeners. As a simple optimization,
 * we'll be sure to always return this empty array instance
 * if the filter did not find any results
 */
const emptyInProgressJobsArray: DataJob[] = [];

/**
 * Provides the current list of IN-PROGRESS/SUBMITTED jobs.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const inProgressJobsSelector = createSelector(
  useDataVolumeJobsSelector,
  (dataJobs) => {
    const filteredDataJobs = Object.values(dataJobs).filter(
      (job) =>
        (!job.supplemental ||
          (job.supplemental &&
            job.params.moduleId.startsWith(SupplFilterJobPrefix))) &&
        (job.status === "IN-PROGRESS" ||
          job.status === "SUBMITTED" ||
          job.status === "ACKNOWLEDGED")
    );
    if (isEmpty(filteredDataJobs)) return emptyInProgressJobsArray;
    else return filteredDataJobs;
  }
);

/**
 * Since the selector performs a `filter` on the dataJobs,
 * it means we'll always return a new array instance and
 * thus trigger our listeners. As a simple optimization,
 * we'll be sure to always return this empty array instance
 * if the filter did not find any results
 */
const emptyIncompleteJobsArray: DataJob[] = [];

/**
 * Provides the current list of INCOMPLETE jobs.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const incompleteJobsSelector = createSelector(
  useDataVolumeJobsSelector,
  (dataJobs) => {
    const filteredDataJobs = Object.values(dataJobs).filter(
      (job) => job.status === "INCOMPLETE"
    );
    if (isEmpty(filteredDataJobs)) return emptyIncompleteJobsArray;
    else return filteredDataJobs;
  }
);

/**
 * Since the selector performs a `filter` on the dataJobs,
 * it means we'll always return a new array instance and
 * thus trigger our listeners. As a simple optimization,
 * we'll be sure to always return this empty array instance
 * if the filter did not find any results
 */
const emptyErroredJobsArray: DataJob[] = [];

/**
 * Provides the current list of ERRORED jobs.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const erroredJobsSelector = createSelector(
  useDataVolumeJobsSelector,
  (dataJobs) => {
    const filteredDataJobs = Object.values(dataJobs).filter(
      (job) => job.status === "ERROR"
    );
    if (isEmpty(filteredDataJobs)) return emptyErroredJobsArray;
    else return filteredDataJobs;
  }
);

/**
 * Since the selector performs a `filter` on the dataJobs,
 * it means we'll always return a new array instance and
 * thus trigger our listeners. As a simple optimization,
 * we'll be sure to always return this empty array instance
 * if the filter did not find any results
 */
const emptyDataLimitJobsArray: DataJob[] = [];

/**
 * Provides the current list of DATA-LIMIT jobs.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const dataLimitJobsSelector = createSelector(
  useDataVolumeJobsSelector,
  (dataJobs) => {
    const filteredDataJobs = Object.values(dataJobs).filter(
      (job) => job.status === "DATA-LIMIT"
    );
    if (isEmpty(filteredDataJobs)) return emptyDataLimitJobsArray;
    else return filteredDataJobs;
  }
);

/**
 * Since the selector performs a `filter` on the dataJobs,
 * it means we'll always return a new array instance and
 * thus trigger our listeners. As a simple optimization,
 * we'll be sure to always return this empty array instance
 * if the filter did not find any results
 */
const emptyStoppedJobsArray: DataJob[] = [];

/**
 * Provides the current list of STOPPED jobs.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const stoppedJobsSelector = createSelector(
  useDataVolumeJobsSelector,
  (dataJobs) => {
    const filteredDataJobs = Object.values(dataJobs).filter(
      (job) => job.status === "STOPPED"
    );
    if (isEmpty(filteredDataJobs)) return emptyStoppedJobsArray;
    else return filteredDataJobs;
  }
);

/**
 * Provides the current list of ERRORED jobs.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const erroredSupplJobsSelector = createSelector(
  useDataVolumeJobsSelector,
  (dataJobs) =>
    Object.values(dataJobs).filter(
      (job) => job.supplemental && job.status === "ERROR"
    )
);

export default dataVolumeSlice;

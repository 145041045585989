import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUseSliceSelector } from "../util";
import { AoiID } from "../../@types/AreaOfInterest.model";
import { DataJob, JobHash, JobStatus } from "./data-volume";
import { RootState } from "state";
import {
  ChartTypeDataDefinition,
  ChartTypesEnum,
  HistogramDataOptions
} from "../../@types/chart.model";

/**
 * Stored chart data that is going to populate the chart
 * @prop {ChartTypesEnum} chartType is the type of chart to be displayed
 * @prop {ChartTypeDataDefinition} chartData is the formatted data of the chart to be displayed
 */
export type ChartData = Record<string, ChartTypeDataDefinition>;

export type ChartDataStore = Record<ChartId, ChartData>;

type ChartId = string;
/**
 * @prop {string} chartId is the ID of the created chart Data
 */
export interface KeyInterface {
  chartId: ChartId;
}

/**
 * @prop {ChartData} chartData the state of the chart data
 */
export interface ChartDataPayload {
  chartData: ChartData;
}

/**
 * Stored chart options that is going to generate the aggregation database query.
 * @prop {AoiID[]} aois is an array of the AOI ids to be included in the chart
 * @prop {string[]} sources is an array of strings of the the data source names to be included. ["sxxi", "itu_t", "industry_canada", "sxxi_demo"]
 * @prop {string[]} fields is an array of strings comprised of the CommonDataLayerModel property to aggregate by.
 */
interface OptionsDataObject {
  aois: AoiID[];
  sources: string[];
  fields: string[];
  histogramOptions?: HistogramDataOptions;
}

/**
 * Stored chart options that is going to create the search query for the chart
 * @prop {ChartTypesEnum} chartType is the type of chart to be displayed
 * @prop {Record<string, OptionsDataObject>} optionsData is the selected options of the chart to be displayed
 * @prop {submitted | received} status Status of the chart request
 */
export interface ChartOption {
  chartType: ChartTypesEnum;
  optionsData: OptionsDataObject;
  status: "submitted" | "received";
}

/**
 * Maps chartId -> ChartOption
 */
export type ChartOptionStore = Record<ChartId, ChartOption>;

/**
 * @prop {ChartOption} chartOption the state of the chart option
 */
export interface ChartOptionsPayload extends KeyInterface {
  chartOption: ChartOption;
}

export type ChartJob = Pick<
  DataJob,
  "label" | "jobHash" | "status" | "message"
> & {
  chartId: ChartId;
  chartOptions: ChartOption;
};

export type ChartJobs = {
  [key: JobHash]: ChartJob;
};

export type UpdateChartJobsType = {
  jobHash: JobHash;
  status?: JobStatus;
  pitId?: string;
  message?: string;
};

export interface ChartState {
  chartData: ChartDataStore;
  chartJobs: ChartJobs;
  chartOptions: ChartOptionStore;
}

/**
 * Sets the state of the slice
 */
const initialState: ChartState = {
  chartData: {},
  chartJobs: {},
  chartOptions: {}
};

/**
 * @param {object} createSlice builds an object from the name, initial state, and reducer
 */
export const chartSlice = createSlice({
  name: "chartState",
  initialState,
  reducers: {
    /**
     * Adds a job to chartJobs
     */
    addChartJob(state, action: PayloadAction<ChartJob>) {
      state.chartJobs[action.payload.jobHash] = action.payload;
    },
    /**
     * Updates the job status with the message bus status update
     */
    updateChartJob(state, action: PayloadAction<UpdateChartJobsType>) {
      const { jobHash } = action.payload;
      state.chartJobs[jobHash] = {
        ...state.chartJobs[jobHash],
        ...action.payload
      };
    },
    /**
     * Removes specified job from chartJobs state
     */
    deleteChartJob(state, action: PayloadAction<JobHash>) {
      delete state.chartJobs[action.payload];
    },
    /**
     * Add chart data value
     */
    addChartData(
      state,
      action: PayloadAction<KeyInterface & ChartDataPayload>
    ) {
      const { chartId, chartData } = action.payload;
      state.chartData[chartId] = chartData;
    },
    /**
     *
     * Update chart data values. A value will only be changed if it is defined in the payload.
     * Will do nothing if the chart data does not exist.
     */
    updateChartData(
      state,
      action: PayloadAction<KeyInterface & Partial<ChartDataPayload>>
    ) {
      const { chartId, chartData } = action.payload;
      if (state?.chartData?.[chartId] === undefined) {
        console.error(`Chart ${chartId} was never initialized in the store.`);
        return;
      }
      state.chartData[chartId] = chartData;
    },
    /**
     * function to delete a chart data from the store.
     */
    deleteChartData(state, action: PayloadAction<KeyInterface>) {
      const { chartId } = action.payload;
      delete state.chartData[chartId];
    },
    /**
     * Add chart options
     */
    addChartOptions(state, action: PayloadAction<ChartOptionsPayload>) {
      const { chartId, chartOption } = action.payload;
      state.chartOptions[chartId] = chartOption;
    },
    /**
     *
     * Update chart option values. A value will only be changed if it is defined in the payload.
     * Will do nothing if the chart option id does not exist.
     */
    updateChartOptions(state, action: PayloadAction<ChartOptionsPayload>) {
      const { chartId, chartOption } = action.payload;
      if (state?.chartOptions?.[chartId] === undefined) {
        console.error(
          `Chart Option ${chartId} was never initialized in the store.`
        );
        return;
      }
      state.chartOptions[chartId] = {
        ...state.chartOptions[chartId],
        ...chartOption
      };
    },
    /**
     * function to delete a chart option from the store.
     */
    deleteChartOptions(state, action: PayloadAction<KeyInterface>) {
      const { chartId } = action.payload;
      delete state.chartOptions[chartId];
    }
  }
});

export const {
  addChartJob,
  updateChartJob,
  deleteChartJob,
  addChartData,
  updateChartData,
  deleteChartData,
  addChartOptions,
  updateChartOptions,
  deleteChartOptions
} = chartSlice.actions;

export const useChartStateSelector = createUseSliceSelector(chartSlice);

export const useChartDataVolumeJobsSelector = (state: RootState) =>
  state.chartState.chartJobs;

/**
 * Provides the current list of IN-PROGRESS/SUBMITTED jobs.
 *
 * This selector only runs the filter calculation IF AND ONLY IF useDataVolumeJobsSelector
 * returns a different result (i.e. the jobs have changed)
 */
export const inProgressChartJobsSelector = createSelector(
  useChartDataVolumeJobsSelector,
  (dataJobs) =>
    Object.values(dataJobs).filter(
      (job) => job.status === "IN-PROGRESS" || job.status === "SUBMITTED"
    )
);

export default chartSlice;

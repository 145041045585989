import {
  EuiOverlayMask,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiProvider
} from "@elastic/eui";
import CountDownSpinner from "./countdown-spinner";

export interface LogOutModalProps {
  header: string;
  body: string;
  footer: string;
  onClose: () => void;
  secondsRemaining: number;
}

const LogOutModal: React.FC<LogOutModalProps> = (props) => {
  const renderBody = (body: string) => {
    return body === "" ? body : <p>{body}</p>;
  };

  return (
    <EuiProvider colorMode="dark">
      <EuiOverlayMask>
        <EuiModal onClose={props.onClose}>
          <EuiModalHeader>{props.header}</EuiModalHeader>
          <EuiModalBody>
            {renderBody(props.body)}
            <CountDownSpinner
              secondsRemaining={props.secondsRemaining}
            ></CountDownSpinner>
          </EuiModalBody>
          <EuiModalFooter>{props.footer}</EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    </EuiProvider>
  );
};

export default LogOutModal;

import { Component } from "react";
import ReactDOM from "react-dom";
import {
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiProvider,
  htmlIdGenerator
} from "@elastic/eui";
import * as _ from "lodash";
import { clone } from "lodash";
import { Provider } from "react-redux";
import { store } from "state";

type ModalServiceProps = {
  id?: string;
  header?: JSX.Element | string;
  body: JSX.Element | string;
  footer?: JSX.Element | string;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
};

export class Modal extends Component {
  private header;
  private body;
  private footer;
  private onModalClose;
  private className;
  constructor(props: ModalServiceProps) {
    super(props);
    const { header, body, footer, className, onClose } = props;
    this.onModalClose = onClose;
    this.header = header;
    this.body = body;
    this.footer = footer;
    this.className = className;
  }
  componentWillUnmount() {
    if (_.isFunction(this.onModalClose)) this.onModalClose();
  }
  renderHeader() {
    return (
      <EuiModalHeader>
        <EuiModalHeaderTitle css={{ fontSize: "16px" }}>
          {this.header}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
    );
  }
  renderBody() {
    return <EuiModalBody>{this.body}</EuiModalBody>;
  }
  renderFooter() {
    return <EuiModalFooter>{this.footer}</EuiModalFooter>;
  }
  render() {
    return (
      <EuiModal onClose={this.onModalClose} style={{ opacity: "0.95" }}>
        {this.header ? this.renderHeader() : null}
        {this.body ? this.renderBody() : null}
        {this.footer ? this.renderFooter() : null}
      </EuiModal>
    );
  }
}

/**
 * Constants for modal dom ids
 */
const ModalContainerId = htmlIdGenerator("modal_container_")();
const ModalId = htmlIdGenerator("modal_")();

function getModalContainer() {
  let modalEl = document.getElementById(ModalContainerId);
  if (!modalEl) {
    let div = document.createElement("div");
    div.id = ModalContainerId;
    document.body.appendChild(div);
    modalEl = div;
  }
  return modalEl;
}

/**
 * An object providing open and close methods for application modal
 * with overlay mask
 */
export const ModalService = {
  /* open a modal, if another is already open, close it first
     per the "Don't stack modals" design - https://elastic.github.io/eui/#/guidelines/modals
  */
  open: function open(opts: ModalServiceProps) {
    const modalContainerEl = getModalContainer();
    let modalId = document.getElementById(ModalId);

    if (modalId) {
      console.warn("Overlapping modals detected.");
      ModalService.close();
    }

    const { onClose } = opts;
    const props = clone(opts);
    props.onClose = () => {
      ModalService.close();
      if (_.isFunction(onClose)) {
        onClose();
      }
    };
    props.className = _.compact([props.className, "modal"]).join(" ");
    props.id = ModalId;
    ReactDOM.render(
      <Provider store={store}>
        <EuiProvider colorMode="dark">
          <Modal {...props} />
        </EuiProvider>
      </Provider>,
      modalContainerEl,
      () => {
        // render done
        if (_.isFunction(props.onOpen)) {
          props.onOpen();
        }
      }
    );
  },
  close: function close() {
    let modalEl = document.getElementById(ModalContainerId);
    if (modalEl) {
      ReactDOM.unmountComponentAtNode(modalEl);
    }
  }
};

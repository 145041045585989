import { useEffect, useState } from "react";
import { EuiProgress, EuiText, EuiTextAlign } from "@elastic/eui";

export interface CountDownSpinnerProps {
  secondsRemaining: number;
}

const CountDownSpinner: React.FC<CountDownSpinnerProps> = ({
  secondsRemaining
}) => {
  const [secondsToDisplay, setSecondsToDisplay] = useState(secondsRemaining);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsToDisplay(secondsToDisplay - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [secondsToDisplay]);

  const renderRemaining = () => {
    return secondsToDisplay <= 0
      ? "Session Expired"
      : `Seconds remaining ${secondsToDisplay}`;
  };

  return (
    <EuiText>
      <EuiTextAlign textAlign="center">
        <p>{renderRemaining()}</p>
        <div>
          <EuiProgress size="s" color="accent" />
        </div>
      </EuiTextAlign>
    </EuiText>
  );
};

export default CountDownSpinner;

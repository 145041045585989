import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUseSliceSelector } from "../util";
import { DrawingMode } from "../../@types/drawing-mode";
import { adjustCesiumElements } from "components/helpers/exercise/adjust-cesium-elements";

export interface UIState {
  drawingMode: DrawingMode;
  showExerciseBanner: boolean;
  showDataAsDots: boolean;
}

const initialState: UIState = {
  drawingMode: DrawingMode.NONE,
  showExerciseBanner: false,
  showDataAsDots: false
};

export interface DrawingModePayload {
  drawingMode: DrawingMode;
}

export const uiStateSlice = createSlice({
  name: "ui-state",
  initialState,
  reducers: {
    /**
     * Overwrite the current DrawingMode value, or create one in state if it
     * doesn't exist.
     */
    setDrawingMode(state, action: PayloadAction<DrawingModePayload>) {
      if (action?.payload?.drawingMode)
        state.drawingMode = action.payload.drawingMode;
    },
    /**
     * Indicate whether the exercise banner is showing
     */
    setShowExerciseBanner(state, action: PayloadAction<boolean>) {
      state.showExerciseBanner = action?.payload;
      adjustCesiumElements(action?.payload);
    },

    setShowDataAsDots(state, action: PayloadAction<boolean>) {
      state.showDataAsDots = action?.payload;
    }
  }
});

export const { setDrawingMode, setShowExerciseBanner, setShowDataAsDots } =
  uiStateSlice.actions;

export const useUiStateSelector = createUseSliceSelector(uiStateSlice);

export default uiStateSlice;

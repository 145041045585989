import React, { Fragment } from "react";
import "./loading-overlay.scss";
import { useLoadingOverlayContext } from "context/loading-overlay-context";
import { EuiPortal, EuiOverlayMask, EuiTitle, EuiSpacer, EuiText } from "@elastic/eui";
import { LoadingOverlayUnderline } from "../../assets";
import { combineUrlFragments } from 'util/url-builder';
import { getDirectory } from 'util/base-url';

export const LoadingOverlay: React.FC<{ fetching?: boolean }> = () => {
  const { dataToBeLoaded, fetchingMsg, errorMsg } = useLoadingOverlayContext();
  const stillLoading = dataToBeLoaded.includes(false);
  
  if(errorMsg){
    const basePathDirectory = getDirectory();
    const root = document.querySelector('#root');
    if(root) {
      root.classList.add('root-adjustment');
    }

    return (
      <div className="loading-page">
        <img
          className="loading-screen-background"
          src={`${combineUrlFragments(basePathDirectory, "/logout/earth.png")}`}
          alt="Earth from outerspace"
        />
        <div className="loading-screen">
          <h1 className="loading-screen-header">EMBM</h1>
          <EuiText color="white">
            <p className="error-message">{errorMsg}</p>
          </EuiText>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {stillLoading ? (
        <EuiPortal>
          <EuiOverlayMask>
            <div className="loading-overlay">
              <div className="center-text">
                <div className="icon-text">
                  <EuiTitle size="l" className="loading-title">
                    <div>EMBM-J-SA</div>
                  </EuiTitle>
                  <img src={LoadingOverlayUnderline} alt="loading-overlay" />
                </div>
                <EuiSpacer />
                {<div className="loading-bar-container">
                  <div className="loading-bar-path">
                    <span
                      id="loading-bar-element"
                      className="loading-bar-element-shape"
                    ></span>
                  </div>
                </div>}
                <EuiSpacer size='s' />
                {!!fetchingMsg && <span className='loading-fetch-text'>{fetchingMsg}</span>}
              </div>
            </div>
          </EuiOverlayMask>
        </EuiPortal>
      ) : null}
    </Fragment>
  );
};

import { htmlIdGenerator } from "@elastic/eui";
import { isFunction } from "lodash";
import { ReactElement } from "react";
import ReactDOM from "react-dom";

/**
 * Open a flyout
 *
 * Usage example
 *
 * FlyoutService.open(opts, closeAllFlyouts);
 * FlyoutService.open(
 * {
 *   onClose: ()=>{}, // optional callback for when flyout is closed
 *   windowId: String, // optional id for the flyout, if not provided, will be random
 *   children: ReactComponent - children of the flyout
 *   ...EuiFlyoutProperties // any properties for EuiFlyout component - will be pass through
 *
 * },
 *   Boolean //
 * );
 *
 * Flyout.close(windowId) // close a flyout
 */
export type FloatingWindowComponentProps = {
  body: JSX.Element;
  windowId?: string;
  bodyProps?: object;
  onClose?: () => void;
  styles?: {};
};

type FloatingWindowServiceType = {
  htmlIdGenerator: () => string;
  open: (
    window: ReactElement<FloatingWindowComponentProps>,
    opts?: FloatingWindowProps
  ) => { id: string; close: () => void };
  close: (id: string) => void;
  closeAll: () => void;
};

// private internal implementation used to hide internal implementation details
type FloatingWindowServiceImpl = {
  windows: Map<string, () => void>;
} & FloatingWindowServiceType;

type FloatingWindowProps = {
  windowId?: string;
  closeAll?: boolean;
};

const FloatingWindowService: FloatingWindowServiceImpl = {
  htmlIdGenerator: htmlIdGenerator("window-"),
  windows: new Map(),
  open: function open(window: ReactElement, opts: FloatingWindowProps) {
    const { windowId, closeAll = false } = opts;
    const {
      props: { windowProps: { onClose: onWindowClose = null } = {} } = {}
    } = window;
    const elId = (windowId || this.htmlIdGenerator()) + "-container";
    if (closeAll) {
      this.windows.forEach((_closeFn: () => void, i: string) => {
        this.close(i);
      });
    }
    const onClose = () => {
      if (isFunction(onWindowClose)) {
        onWindowClose();
      }
    };
    this.windows.set(elId, onClose);

    let modalEl = document.getElementById(elId);
    if (!modalEl) {
      let div = document.createElement("div");
      div.id = elId;
      document.body.appendChild(div);
      modalEl = div;
    }
    ReactDOM.render(window, modalEl);
    return { id: elId, close: onClose };
  },
  close: function close(windowId) {
    const elId = windowId + "-container";
    const onCloseFn = this.windows.get(elId);
    this.windows.delete(elId);
    let modalEl = document.getElementById(elId);
    if (modalEl) {
      ReactDOM.unmountComponentAtNode(modalEl);
    }
    onCloseFn && onCloseFn();
  },
  closeAll: function closeAll() {
    this.flyouts.forEach((_closeFn: () => void, i: string) => {
      this.close(i);
    });
  }
};

export default FloatingWindowService as FloatingWindowServiceType;

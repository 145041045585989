import { SystemStatus } from "@mvcr/mvcr-common/dist/models/systemStatus.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual, set } from "lodash";
import { createUseSliceSelector, createUseValueSelector } from "state/util";

// Maps PA _id -> PropagationAnalysis
export type OpState = {
  systemStatus: SystemStatus;
};

const initialState: OpState = {
  systemStatus: null
};

export const opStatusSlice = createSlice({
  name: "opStatus",
  initialState,
  reducers: {
    /**
     * Overwrite the current list of workspace propagations,
     * or create one in state if it doesn't exist.
     */
    setOpStatus(state, action: PayloadAction<SystemStatus>) {
      const prop = action.payload;
      set(state, "systemStatus", prop);
    }
  }
});

export const { setOpStatus } = opStatusSlice.actions;

export const useOpStatusSelector = createUseSliceSelector(opStatusSlice);

/**
 * Returns a selector for the value of the systemStatus array, performing a lodash isEqual
 * to examine the values of the array for equality instead of the standard equality check of array identity
 */
export const useSystemStatusSelector = createUseValueSelector(
  opStatusSlice,
  "systemStatus",
  isEqual
);

export default opStatusSlice;

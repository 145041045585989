import { CapabilityComparisonRequestParams } from "../@types/capability-comparison.model";
import md5 from "md5";

export const createCapabilityComparisonJobHash = (
  capabilityComparisonRequestParams: CapabilityComparisonRequestParams
) => {
  const receivers = capabilityComparisonRequestParams?.receivers || [];
  const jammers = capabilityComparisonRequestParams?.jammers || [];

  const sortedReceivers = [...receivers].sort();
  const sortedJammers = [...jammers].sort();

  // Passing sorted recievers & jammers into an array to preserve order for stringify
  const stringifiedRequest = JSON.stringify([sortedReceivers, sortedJammers]);

  return md5(stringifiedRequest);
};

import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import mvcrApi from "./api/mvcrApi";
import uiStateSlice from "./slices/ui-state";
import uiSettingsSlice from "./slices/ui-settings";
import timelineSettingsSlice from "./slices/timeline";
import opsClockComparisonSlice from "./slices/ops-clock-comparison";
import counterSlice from "./slices/loading-progress";
import uploadFileEntriesSlice from "./slices/upload-file-entries";
import dataVolumeSlice from "./slices/data-volume";
import keycloakSlice from "./slices/keycloak";
import currentTimeChangeSlice from "./slices/current-time-change";
import userSettingsStateSlice from "./slices/user-settings-analyses";
import propagationSlice from "./slices/propagation";
import connectionStatusSlice from "./slices/connection-status";
import toastSlice from "./slices/toast";
import bandwidthSlice from "./slices/bandwidth";
import chartSlice from "./slices/chart";
import capabilityComparisonSlice from "./slices/capability-comparison";
import opStatusSlice from "./slices/opStatus";
import datasourceStatusSlice from "./slices/datasourceStatus";

export const store = configureStore({
  reducer: {
    [mvcrApi.reducerPath]: mvcrApi.reducer,
    [uiStateSlice.name]: uiStateSlice.reducer,
    [uiSettingsSlice.name]: uiSettingsSlice.reducer,
    [timelineSettingsSlice.name]: timelineSettingsSlice.reducer,
    [opsClockComparisonSlice.name]: opsClockComparisonSlice.reducer,
    [counterSlice.name]: counterSlice.reducer,
    [uploadFileEntriesSlice.name]: uploadFileEntriesSlice.reducer,
    [chartSlice.name]: chartSlice.reducer,
    [capabilityComparisonSlice.name]: capabilityComparisonSlice.reducer,
    [dataVolumeSlice.name]: dataVolumeSlice.reducer,
    [keycloakSlice.name]: keycloakSlice.reducer,
    [currentTimeChangeSlice.name]: currentTimeChangeSlice.reducer,
    [userSettingsStateSlice.name]: userSettingsStateSlice.reducer,
    [propagationSlice.name]: propagationSlice.reducer,
    [connectionStatusSlice.name]: connectionStatusSlice.reducer,
    [toastSlice.name]: toastSlice.reducer,
    [bandwidthSlice.name]: bandwidthSlice.reducer,
    [opStatusSlice.name]: opStatusSlice.reducer,
    [datasourceStatusSlice.name]: datasourceStatusSlice.reducer
  },
  middleware: (getDefaultMiddleware) => {
    let defaultMiddlewares = getDefaultMiddleware({
      serializableCheck: false
      // {
      //   ignoredActionPaths: [
      //     "meta",
      //     "payload.usersettings.workspaceMapPositionZoom"
      //   ],
      //   ignoredPaths: ["usersettings.usersettings.workspaceMapPositionZoom"]
      // }
      // immutableCheck: {
      //   ignoredPaths: ["dataVolume.data"]
      // }
    });
    return defaultMiddlewares.concat(mvcrApi.middleware);
  },
  devTools: {
    stateSanitizer: (state: any) => {
      //dataVolume can be massive object, so replace it with some canned text
      // so as to keep the redux dev tool from crashing
      return state?.dataVolume
        ? {
            ...state,
            dataVolume: {
              ...state.dataVolume,
              data: "<<LONG_BLOB>>"
            }
          }
        : state;
    }
  }
});

// Export typed useSelector for consumption
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Export typed useDispatch for consumption
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

import { EuiConfirmModalProps } from "@elastic/eui";
import ReactDOM from "react-dom";
import ConfirmationModal from "../components/confirmation-modal";

export const ConfirmationModalService = {
  open: function open(
    opts: Partial<EuiConfirmModalProps> & { confirmId?: string }
  ) {
    opts = opts || {};
    const { onCancel, confirmId, ...rest } = opts;
    let elId = confirmId || "confirmation_modal";
    let props = {
      onCancel: () => {
        ConfirmationModalService.close(elId);
        if (onCancel) {
          onCancel();
        }
      },
      ...rest
    };

    let modalEl = document.getElementById(elId);
    if (!modalEl) {
      let div = document.createElement("div");
      div.id = elId;
      document.body.appendChild(div);
      modalEl = div;
    }
    ReactDOM.render(<ConfirmationModal {...props} />, modalEl);
  },
  close: function close(elId: string) {
    let modalEl = document.getElementById(elId);
    if (modalEl) {
      ReactDOM.unmountComponentAtNode(modalEl);
    }
  }
};
